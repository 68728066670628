import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';

import { EMPTY, Subject, of, combineLatest, BehaviorSubject } from 'rxjs';
import { takeUntil, map, switchMap, take, delay, tap, startWith, catchError, share, filter, shareReplay } from 'rxjs/operators';
import { mapSuccessData, successData, truthy } from 'src/app/utils/rxjs-operators';

import { JwtHelperService } from 'angular-jwt-updated';

import { createErrorResult, createLoadingResult, createSuccessResult } from 'src/app/utils/api-helpers';

import { City, JobRole, JobRoleLevel, JobSubFamily, XrefService } from 'src/app/services/api/xref.service';
import { DataTable } from 'src/app/services/api.service';
import { IntakeService, JobDescForm } from 'src/app/services/api/intake.service';
import { DialogService } from 'src/app/services/dialog.service';

import { Editor, Toolbar, schema } from 'ngx-editor';
import { Skill } from 'src/app/services/api/skills.service';

import { CriteriaKeys } from 'src/app/enum';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-intake-form',
  templateUrl: './intake-form.component.html',
  styleUrls: ['./intake-form.component.scss']
})
export class IntakeFormComponent implements OnDestroy, OnInit {
  private destroy$ = new Subject<void>();

  private jwtService = new JwtHelperService();

  jwtParams$ = this.activatedRoute.queryParams.pipe(
    map(params => params.jwt as string),
    truthy(),
    map(jwt => {
      interface Token { __type: string }
      let token: Token | null = null;
      try { token = this.jwtService.decodeToken<Token>(jwt); } catch (ex) { null; }

      return { token, jwt };
    }),
    switchMap(({ token, jwt }) => {
      if (token?.__type === 'job-collaborate') {
        return of({ jwt });
      } else {
        void this.router.navigateByUrl('/');
        return EMPTY;
      }
    }),
    takeUntil(this.destroy$)
  );

  delayResponse$ = <T>(data: T, err = false) => of(data).pipe(
    delay(2 * 1000),
    tap(() => { if (err) { throw 'err'; } }),
    take(1),
    map(data => createSuccessResult(data)),
    startWith(createLoadingResult()),
    catchError((error: unknown) => {
      return of(createErrorResult(error as Error));
    })
  );

  // text editor options
  editor: Editor = new Editor();
  html = '';
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['align_left', 'align_center', 'align_right', 'align_justify']
  ];

  fileFormats = '.pdf, .docx, .doc, .rtf';

  form = this.formBuilder.group({
    jobDesc: [null, []],
    jobDescText: ['', Validators.required],
    jobSubFamilyId: ['', []],
    jobSubFamilyText: ['', []],
    jobRoleId: ['', Validators.required],
    jobLevel: ['', []],
    geoId: ['', []],
    countryId: ['4', []],
    remoteOrOnsite: ['', []],
    salaryFrom: [0, []],
    salaryTo: [0, []]
  });

  saveForm$ = new Subject<void>();
  saveFormSubmit$ = this.saveForm$.pipe(
    switchMap(() => of(this.form)),
    switchMap(form => {
      form.markAllAsTouched();

      if (!form.valid || !form.value) return of({ submitted: true as const, valid: false as const });

      return this.jwtParams$.pipe(
        switchMap(() => this.intake.saveForm(form.value as JobDescForm)),
        map(result => ({ submitted: true as const, valid: true as const, result }))
      );
    }),
    startWith({ submitted: false as const }),
    takeUntil(this.destroy$)
  );

  jobRoleLevels$ = this.xref.get<JobRoleLevel[]>({
    path: 'job-role-levels'
  }).pipe(
    mapSuccessData(data => data.map(x => ({ value: x.jobRoleLevelId, text: x.jobRoleLevel }))),
    takeUntil(this.destroy$)
  );

  allJobCertifications$ = this.xref.get<DataTable<Skill>>({
    path: 'certificates'
  }).pipe(
    mapSuccessData(data => data.dataTable.map(x => ({ value: x.skillId, text: x.skillName }))),
    takeUntil(this.destroy$)
  );

  allJobRoles$ = this.xref.get<DataTable<JobRole>>({
    path: 'job-roles'
  }).pipe(
    mapSuccessData(data => data.dataTable.map(x => ({ value: x.jobRoleId, text: x.jobRole }))),
    takeUntil(this.destroy$)
  );

  allJobSkills$ = this.xref.get<DataTable<Skill>>({
    path: 'skills'
  }).pipe(
    mapSuccessData(data => data.dataTable.map(x => ({ value: x.skillId, text: x.skillName }))),
    takeUntil(this.destroy$)
  );

  // queries save criteria and returns criteria associated with the entered role and description
  rawCriteria$ = this.saveFormSubmit$.pipe(
    filter(form => form.submitted && form.valid && form.result.finished),
    map(() => [
      this.form.controls.jobRoleId.value,
      this.form.controls.jobDescText.value
    ]),
    switchMap(([jobRoleId, jobDescText]) => this.intake.saveCriteria(jobRoleId, jobDescText)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  // separates criteria api result into required/preferred buckets
  criteria$ = this.rawCriteria$.pipe(
    mapSuccessData(data => {
      // clear criteria container so that it doesn't display criteria from previous query
      this.criteria.forEach(x => {
        x.preferred.splice(0);
        x.required.splice(0);
      });

      for (const criteria of data) {
        const index = this.criteria.findIndex(x => x.key === criteria.key);

        criteria.items.forEach(item => {
          if (item.mandatory === true) {
            this.criteria[index].required.push(item);
          }

          if (item.preferred === true) {
            this.criteria[index].preferred.push(item);
          }
        });
      }

      return this.criteria;
    }),
    truthy(),
    takeUntil(this.destroy$)
  );

  // container to hold criteria, initialized with criteria$, updated with refreshSearchCriteria$
  criteria: Criteria[] = [
    { key: CriteriaKeys.JobRoles, preferred: [], required: [] },
    { key: CriteriaKeys.Skills, preferred: [], required: [] },
    { key: CriteriaKeys.Certifications, preferred: [], required: [] }
  ];

  refreshSearchCriteria$ = new BehaviorSubject<void>(undefined);

  searchCriteria = (key: string) => (searchValue?: string) =>
    combineLatest([
      this.allJobRoles$,
      this.allJobSkills$,
      this.allJobCertifications$,
      this.rawCriteria$,
      this.refreshSearchCriteria$
    ]).pipe(
      // return roles, skills or certificates api result depending on current key
      map(([roles, skills, certifications, rawCriteria]) => {
        switch (key) {
        case CriteriaKeys.JobRoles:
          if (rawCriteria.success && roles.success) {
            const criteriaRoles = rawCriteria.data.filter(data => data.key === CriteriaKeys.JobRoles);

            // push each role from rawCriteria to the start of roles array, maintaining the order in which the roles are returned from the api
            for (const role of criteriaRoles) {
              for (const item of role.items) {
                roles.data.splice(role.items.indexOf(item), 1, { value: item.value.toString(), text: item.text });
              }
            }

          }

          return roles;

        case CriteriaKeys.Skills:
          if (rawCriteria.success && skills.success) {
            const criteriaSkills = rawCriteria.data.filter(data => data.key === CriteriaKeys.Skills);

            // push each skill from rawCriteria to the start of skills array, maintaining the order in which the skills are returned from the api
            for (const skill of criteriaSkills) {
              for (const item of skill.items) {
                skills.data.splice(skill.items.indexOf(item), 1, { value: item.value.toString(), text: item.text });
              }
            }

          }

          return skills;

        case CriteriaKeys.Certifications:
          if (rawCriteria.success && certifications.success) {
            const criteriaCertifications = rawCriteria.data.filter(data => data.key === CriteriaKeys.Certifications);

            // push each certification from rawCriteria to the start of certifications array, maintaining the order in which the certifications are returned from the api
            for (const certification of criteriaCertifications) {
              for (const item of certification.items) {
                certifications.data.splice(certification.items.indexOf(item), 1, { value: item.value.toString(), text: item.text });
              }
            }

          }

          return certifications;

        default:
          return roles;

        }
      }),
      mapSuccessData(data => {
        // filter new array of items by what was entered into the search bar
        const filteredItems = data.filter(item => !searchValue || searchValue.toLowerCase().split(/\s+/).every(searchString => item.text.toLowerCase().includes(searchString)));

        // only return values that are not already marked as mandatory or preferred inside of the criteria container
        return filteredItems.filter(item => Object.values(this.criteria).every(criteria => !criteria.preferred.map(preferred => preferred.value.toString()).includes(item.value) && !criteria.required.map(required => required.value.toString()).includes(item.value)));
      }),
      share(),
      takeUntil(this.destroy$)
    );

  jobSubFamilies$ = this.xref.get<JobSubFamily[]>({ path: 'job-sub-families' }).pipe(
    mapSuccessData(data => data
      .map(x => ({ value: x.jobSubFamilyId, text: `${x.jobFamilyDesc} - ${x.jobSubFamilyDesc}` }))
    ),
    share(),
    takeUntil(this.destroy$)
  );

  searchSubFamilies = (searchValue: string) => combineLatest([
    of(searchValue)
  ]).pipe(
    switchMap(([searchValue]) => this.jobSubFamilies$.pipe(
      mapSuccessData(data => data
        .filter(x => !searchValue || searchValue.toLowerCase().split(/\s+/).every(y => x.text.toLowerCase().includes(y)))
      ),
      share(),
      takeUntil(this.destroy$)
    ))
  );

  // kept this type as any because JobRole type does not include the jobSubFamilyId, which I need to determine the sub family that matches the role
  jobRoles$ = this.xref.get<DataTable<any>>({
    path: 'job-roles'
  }).pipe(
    mapSuccessData(data => data.dataTable.map(data => data)),
    takeUntil(this.destroy$)
  );

  searchRoles = (searchValue: string) => combineLatest([
    of(searchValue)
  ]).pipe(
    switchMap(([searchValue]) => this.xref.get<DataTable<JobRole>>({ path: 'job-roles', params: { searchValue } })),
    mapSuccessData(data => data.dataTable.map(x => ({ value: x.jobRoleId, text: x.jobRole }))),
    share(),
    takeUntil(this.destroy$)
  );

  searchCity = (searchValue: string) => combineLatest([
    of(searchValue)
  ]).pipe(
    switchMap(([searchValue]) => this.xref.get<DataTable<City>>({ path: 'cities', params: { searchValue } })),
    mapSuccessData(data => data.dataTable.map(x => ({ value: x.geoId, text: x.cityState }))),
    share(),
    takeUntil(this.destroy$)
  );

  @ViewChild('confirmPreviousStepTemplate', { static: false }) confirmPreviousStepTemplate?: TemplateRef<any>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private xref: XrefService,
    private formBuilder: FormBuilder,
    private intake: IntakeService,
    private dialog: DialogService
  ) {
    this.form.controls.jobDesc.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(value => {
      const jsonDescText = value ? schema.nodeFromJSON(value).textContent : '';
      this.form.controls.jobDescText.setValue(jsonDescText);
    });

    combineLatest([
      this.jobRoles$.pipe(
        successData()
      ),
      this.jobSubFamilies$.pipe(
        successData()
      ),
      this.form.controls.jobRoleId.valueChanges.pipe(
        startWith(this.form.controls.jobRoleId.value),
        map(x => x || undefined)
      )
    ]).pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      ([jobRoles, jobSubFamilies, jobRoleId]) => {
        const selectedJobRole = jobRoles.find(role => role.jobRoleId === jobRoleId);

        if (selectedJobRole && selectedJobRole.jobSubFamilyId) {
          const family = jobSubFamilies.find(family => family.value.toString().includes(selectedJobRole.jobSubFamilyId));

          if (family) {
            this.form.controls.jobSubFamilyText.setValue(family.text);
            this.form.controls.jobSubFamilyId.setValue(family.value.toString());
          }
        }
      }
    );
  }

  ngOnInit(): void {
    this.editor = new Editor();
  }

  previous(stepper: MatStepper) {
    if (this.confirmPreviousStepTemplate) {
      this.dialog.showDialog(this.confirmPreviousStepTemplate, { dialog: { title: 'Return to job description' }, item: false }).afterClosed().pipe(
        takeUntil(this.destroy$)
      ).subscribe(confirm => {
        if (confirm) stepper.previous();
      });
    }
  }

  uploadJobDescription() {
    this.dialog.uploadJobDescription({
      dialog: { title: 'Upload Job Description' },
      item: {}
    }).pipe(
      take(1),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  saveForm() {
    this.saveForm$.next();
  }

  hasRequiredField(abstractControl: AbstractControl) {
    if (abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      return validator?.required;
    }
  }

  ngOnDestroy(): void {
    this.editor.destroy();

    this.destroy$.next();
    this.destroy$.complete();
  }
}

interface Criteria {
  key: string;
  preferred: { value: number | string, text: string }[]
  required: { value: number | string, text: string }[]
}