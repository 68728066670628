<ng-container
  *ngIf="{
    campaigntypes: campaigntypes$ | async
  } as results">
  <div class="d-flex mx-2 mt-2 p-3 align-items-center" mat-dialog-title>
    <label class="text-primary">Add Candidates to Campaign</label>
    <mat-spinner class="m-1" [diameter]="30" color="accent" *ngIf="loading"></mat-spinner>

    <button mat-dialog-close mat-mini-fab color="primary" class="ms-auto">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-divider></mat-divider>

  <div mat-dialog-content>
    <ng-container *ngIf="results.campaigntypes && results.campaigntypes.length > 0">
      <form [formGroup]="form">
        <div class="d-flex flex-row">
          <div class="d-flex flex-column">
            <span class="fw-medium text-black">Add:</span>
            <section class="border mt-2" formGroupName="Add">
              <div class="d-flex flex-row me-3 mb-2 mt-2">
                <mat-checkbox formControlName="CurrentSelection" (change)="checkAddSelected('CurrentSelection')"> Current Selection </mat-checkbox>
              </div>

              <mat-divider></mat-divider>

              <div class="d-flex flex-row me-3 mb-2 mt-2 align-items-center">
                <mat-checkbox class="me-3" formControlName="Priority" (change)="checkAddSelected('Priority')"> Priority </mat-checkbox>
                <div class="custom-height">
                  <mat-form-field
                    style="max-height: 35px; max-width: 174px"
                    [ngClass]="form.controls.Add.controls.priorityIds.hasError('required') ? 'no-underline shrink-form-field border border-danger rounded' : 'no-underline shrink-form-field'">
                    <mat-select formControlName="priorityIds" multiple>
                      <mat-option *ngFor="let item of priorityCounts" [value]="item.priorityId"> {{ item.priorityDesc }} {{ '(' }} {{ item.cnt }} {{ ')' }} </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <mat-divider></mat-divider>

              <div class="d-flex flex-row me-3 mb-2 mt-2">
                <mat-checkbox class="me-3" formControlName="Stage" (change)="checkAddSelected('Stage')"> Stage </mat-checkbox>
                <div class="custom-height">
                  <mat-form-field
                    style="max-height: 35px; max-width: 174px"
                    [ngClass]="form.controls.Add.controls.stageIds.hasError('required') ? 'no-underline shrink-form-field border border-danger rounded' : 'no-underline shrink-form-field'">
                    <mat-select formControlName="stageIds" multiple>
                      <mat-option *ngFor="let item of ProgressCounts" [value]="item.stageId"> {{ item.stageDesc }} {{ '(' }} {{ item.cnt }} {{ ')' }} </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <!-- <mat-divider></mat-divider>

            <div class="d-flex flex-row mt-2 mb-2">
              <mat-checkbox formControlName="allIndicator" (change)="checkAddSelected('allIndicator')"> All ({{ data?.total }}) </mat-checkbox>
            </div> -->
            </section>

            <div class="d-flex flex-row mt-2">
              <mat-error *ngIf="submitted && form.controls.Add.hasError('mustselect')"> * Select one </mat-error>
            </div>
          </div>

          <div class="d-flex flex-column ms-3">
            <span class="fw-medium text-black">To:</span>

            <section class="border mt-2" formGroupName="campaignId">
              <ng-container *ngFor="let campaign of results.campaigntypes; let i = index">
                <div class="d-flex flex-row me-3 mt-2 mb-2">
                  <mat-checkbox style="width: max-content" [formControlName]="campaign.campaignId" (change)="checkToSelected(campaign)">
                    {{ campaign.campaignDesc }}
                  </mat-checkbox>
                </div>

                <mat-divider *ngIf="results.campaigntypes && results.campaigntypes.length - 1 > i"></mat-divider>
              </ng-container>
            </section>

            <div class="d-flex flex-row mt-2">
              <mat-error *ngIf="submitted && form.controls.campaignId.hasError('mustselect')"> * Select one </mat-error>
            </div>
          </div>

          <div class="d-flex flex-column ms-3 flex-grow-1">
            <div class="ps-2 border" style="height: 20rem; width: 27rem; overflow-y: auto; overflow-x: auto">
              <label class="body-md mt-2"> Recipients</label>

              <div class="d-flex flex-row align-items-center mt-2" *ngIf="emailListLoading">
                <mat-spinner class="me-3" matSuffix color="accent" diameter="24"> </mat-spinner>
                <label>Loading contact information </label>
              </div>
              <div class="d-flex flex-row align-items-center mt-2" *ngIf="!emailListLoading">
                <mat-icon style="overflow: visible" class="text-warning">warning</mat-icon>
                <div class="ms-2 mt-1">
                  <label> Candidates in gray won't receive message; </label>
                  <label> no email address could be found.</label>
                </div>
              </div>

              <div class="d-flex flex-column">
                <mat-chip
                  [matTooltip]="user.email"
                  style="width: fit-content"
                  *ngFor="let user of emailsList"
                  [removable]="true"
                  (removed)="onUserRemoved(user)"
                  [ngClass]="user.email ? 'mat-background-accent-light mt-2' : 'mt-2'">
                  {{ user.displayName }} <ng-container *ngIf="user.email">({{ user.email }}) </ng-container>

                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ng-container>
  </div>

  <mat-divider></mat-divider>

  <div class="d-flex justify-content-end">
    <label class="p-3">
      <b> {{ emailsList.length }} candidates </b> will be added to <b> {{ selectedCampaign }} </b>
    </label>
  </div>

  <div class="d-flex justify-content-end" mat-dialog-actions>
    <button mat-stroked-button color="primary" mat-dialog-close>Close</button>
    <button mat-raised-button color="primary" [disabled]="!form.valid || emailsList.length === 0 || loading" (click)="submit()">Add to Campaign</button>
  </div>
</ng-container>
