<div class="tasks">
  <div class="d-flex flex-row">
    <div class="w-100 flex-shrink-1">
      <div class="d-flex flex-row mat-background-blue-700">
        <div class="w-50 d-flex align-items-center ps-2">
          <span class="text-white">Tasks</span>
        </div>
        <div class="w-50 d-flex flex-row justify-content-end align-items-center">
          <button mat-button color="primary" class="me-2 text-white" (click)="addTaskAction()">
            <mat-icon class="md-icon-12">add_circle</mat-icon>
            <span>Add Task</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex flex-row">
    <div class="w-100 flex-shrink-1 text-black tasks-content bg-white">
      <ng-container *ngIf="{ tasks: tasks$ | async } as results">
        <ng-container *ngIf="results.tasks">
          <ng-container *ngFor="let task of tasksList; let i = index">
            <div class="d-flex flex-column single-task">
              <div class="d-flex flex-row">
                <div class="w-50 d-flex flex-column justify-content-center" [matTooltip]="task.note">
                  <div class="d-flex flex-row">
                    <span class="d-block body" style="font-weight: 500"> {{ task.title }} </span>
                  </div>
                  <div class="d-flex flex-row lh-1 mt-2">
                    <span class="d-block body-sm"> {{ task.shortNote }} </span>
                  </div>
                  <div class="d-flex flex-row lh-1 mt-2">
                    <span class="d-block body-sm" style="font-weight: 500">
                      {{ task.isComplete === 1 ? 'Complete ' : 'Not complete ' }}
                    </span>
                    <span class="d-block body-sm ps-1"> {{ ' | ' }} {{ task.taskDueDateEpoch * 1000 | date }} </span>
                  </div>
                </div>
                <div class="w-50 d-flex flex-row justify-content-end align-items-center">
                  <div class="d-flex flex-column">
                    <div>
                      <mat-checkbox [checked]="task.isComplete === 1" (change)="complete(i, $event.checked)">
                        <label class="p-1 text-body"> Complete </label>
                      </mat-checkbox>
                    </div>
                  </div>

                  <div class="ms-3">
                    <button mat-icon-button class="ms-5 action-button" (click)="showEditTaskForm(i)">
                      <mat-icon class="action-icon mat-color-gray-600">edit</mat-icon>
                    </button>
                  </div>
                  <div class="ms-2">
                    <button mat-icon-button class="ms-4 action-button" (click)="deleteTask(i)">
                      <mat-icon class="action-icon mat-color-gray-600">remove_circle</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <div class="d-flex flex-row justify-content-end p-2">
            <app-paginator
              [pageIndex]="results.tasks.pagination.offset / results.tasks.pagination.numResults"
              [pageSize]="results.tasks.pagination.numResults"
              [length]="results.tasks.pagination.totalEntries"
              [hideIfSinglePage]="false"
              (page)="changePage($event)">
            </app-paginator>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
