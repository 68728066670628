<ng-container *ngIf="candidatePrioritizationList.length>0">

  <div class="d-flex mx-2 mt-2 p-3" mat-dialog-title>
    <label class="text-primary">Prioritize</label>    

    <button mat-dialog-close mat-mini-fab color="primary" class="ms-auto">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-divider></mat-divider>

  <div class="mt-1" mat-dialog-content>

    <form [formGroup]="form">
      <div class="d-flex">
        <div class="w-100">

          <section class="border" formGroupName="prioritize">

            <ng-container *ngFor="let prioritize of candidatePrioritizationList; let i = index">
              <div class="d-flex flex-row mt-1">
                <mat-checkbox [formControlName]="prioritize.priorityId + ''"
                  (change)="checkSelected(prioritize.priorityId + '', prioritize.priorityDesc)">
                  Priority: {{prioritize.priorityDesc}}
                </mat-checkbox>
              </div>

              <mat-divider *ngIf="i < candidatePrioritizationList.length-1"></mat-divider>
            </ng-container>

          </section>

          <div class="d-flex flex-row mt-2">
            <mat-error *ngIf="submitted && form.controls.prioritize.hasError('mustselect')">
              * Select one
            </mat-error>
          </div>
        </div>
      </div>
    </form>
  </div>

  <mat-divider></mat-divider>

  <div class="d-flex justify-content-end">
    <label class="p-3">
      <b> {{counts}} candidates </b> will be added to <b> Priority : {{selectedPrioritize}} </b>
    </label>
  </div>

  <div class="d-flex justify-content-end" mat-dialog-actions>
    <button mat-stroked-button color="primary" mat-dialog-close>Close</button>
    <button mat-raised-button color="primary" [disabled]="!form.valid||loading" (click)="submit()">Add </button>
  </div>
</ng-container>