import { Component, OnDestroy, Inject } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { takeUntil, switchMap, tap } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';

import { ResumesService } from 'src/app/services/api/resumes.service';

import { DialogData } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-pr-resumes-dialog',
  templateUrl: './pr-resumes-dialog.component.html',
  styleUrls: ['./pr-resumes-dialog.component.scss']
})
export class ResumesDialogComponent implements OnDestroy {
  private destroy$ = new Subject<void>();

  fileFormats = '.pdf, .docx, .doc, .rtf';

  upload$ = new Subject<File[]>();
  uploadResult$ = this.upload$.pipe(
    switchMap(files => this.resumes.upload(files, this._data.item?.profileRef)),
    tap(result => {
      if (this._data.dialog?.closeAfterSuccess && result.uploaded === result.progress.length) {
        timer(1000).pipe(
          takeUntil(this.destroy$)
        ).subscribe(() => {
          this.dialogRef.close(this._data.item?.profileRef);
        });
      }
    }),
    takeUntil(this.destroy$)
  );

  constructor(
    private resumes: ResumesService,

    private dialogRef: MatDialogRef<ResumesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: DialogData<{ profileRef?: string }>
  ) { }

  onSelect(event: NgxDropzoneChangeEvent) {
    if (event.addedFiles.length > 0) {
      this.upload$.next(event.addedFiles);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
