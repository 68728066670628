import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { NgStringPipesModule } from 'ngx-pipes-updated';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';

import { InputTextModule } from 'src/app/components/input-text/input-text.module';

import { OmniSearchComponent } from './pr-omni-search.component';
import { TasksModuleModule } from '../tasks-module/tasks-module.module';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    NgStringPipesModule,

    MatAutocompleteModule,
    MatChipsModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    MatToolbarModule,

    InputTextModule,
    TasksModuleModule,
    UserAvatarModule
  ],
  declarations: [OmniSearchComponent],
  exports: [OmniSearchComponent]
})
export class OmniSearchModule {}
