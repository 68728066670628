import { Injectable, OnDestroy } from '@angular/core';
import { HttpResponse, HttpEventType } from '@angular/common/http';
import { Subject, Observable, timer, ReplaySubject, of, pipe, UnaryFunction } from 'rxjs';
import { takeUntil, map, catchError, tap, finalize, startWith, take, share, delay, switchMap } from 'rxjs/operators';
//import { captureException } from '@sentry/angular-ivy';

import { truthy } from 'src/app/utils/rxjs-operators';

import { AuthService } from './auth.service';
import { CacheService } from './cache.service';
import { ApiResponse, HttpGetRequest, HttpPostRequest, HttpUploadRequest, HttpService, HttpRequestBase } from './http.service';
import { SocketService } from './socket.service';
import { DialogService } from './dialog.service';
import { ApiResponseStatus } from 'src/app/enum';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApiService implements OnDestroy {
  private destroy$ = new Subject<void>();

  ApiResponseStatus = ApiResponseStatus;

  constructor(private auth: AuthService, private cache: CacheService, private http: HttpService, private socket: SocketService, private dialogService: DialogService, private router: Router) {}

  private cacheKey = (path: string, qs: Record<string, any>) =>
    path +
    '?' +
    Object.keys(qs)
      .sort()
      .map(key => {
        return `${key}=${qs[key]}`;
      })
      .join('&');

  get<T = any>(request: HttpGetRequest): Observable<ApiResult<T, HttpGetRequest>> {
    return this.baseGet<T>(request).pipe(this.pipes(request));
  }

  post<T = any>(request: HttpPostRequest): Observable<ApiResult<T, HttpGetRequest>> {
    return this.basePost<T>(request).pipe(this.pipes(request));
  }

  private pipes<T, R extends HttpRequestBase>(request: R): UnaryFunction<Observable<ApiResponse<T>>, Observable<ApiResult<T, R>>> {
    const processError = (error: any): Observable<ApiResultError<R>> => {
      const obs = of({ finished: true as const, success: false as const, error, request });

      if (error?.message === this.ApiResponseStatus.invalidToken) {
        if (this.lastLogout <= new Date().getTime() - 5 * 1000) {
          console.error(error);

          this.lastLogout = new Date().getTime();
        }

        return this.logout$.pipe(
          switchMap(() => obs),
          delay(10 * 1000)
        );
      } else {
        console.error(error);
        // do not show hard error toast on /login for now
        // TODO: determine a more efficient way to accommodate for application-wide errors + when to show the hard error toast
        if (this.router.url.split('?')[0] !== '/login') this.dialogService.showError();
      }

      return obs;
    };

    return pipe(
      share<ApiResponse<T>>(),
      take(1),

      switchMap(response => {
        if (response.status.socketEvent) {
          return this.socket.get<ApiResponse<T>>(response.status.socketEvent);
        }

        return of(response);
      }),
      switchMap(response => {
        // returns ApiResult of type ApiResultError if an error code exists
        if (response.status.code === 1) {
          return processError(new Error(response.status.message || 'Internal API Error'));
        }

        // returns ApiResult of type ApiResultSuccess if an error code does not exist
        return of({ finished: true as const, success: true as const, data: response.data, request, response });
      }),
      catchError((error: unknown) => {
        if ((error as any).error?.status?.code === 1 && (error as any).error.status.message?.length > 0) {
          return processError(new Error((error as any).error.status.message));
        }

        if (error && typeof error === 'object' && !(error instanceof Error) && 'message' in error && typeof error.message === 'string') {
          error = Object.assign(new Error(error.message), error);
        }

        return processError(error);
      }),
      // delay(100000),

      // returns ApiResult of type ApiResultLoading until ApiResultSuccess or ApiResultError is returned above
      startWith({ finished: false as const, success: undefined, request })
    );
  }

  upload<T = any>(request: HttpUploadRequest): Observable<ApiUploadResult> {
    return this.http.upload<T>(request).pipe(
      map(response => {
        if (response instanceof HttpResponse && response.body) {
          if (response.body.status.code === 1) {
            throw new Error(response.body.status.message);
          }
        }

        return response;
      }),
      map(response => {
        if (response.type === HttpEventType.UploadProgress && response.total && response.total > 0) {
          return { progress: [response.loaded / response.total], uploaded: 0, failed: 0, tooLarge: 0 };
        } else if (response.type === HttpEventType.Response) {
          return { progress: [1], uploaded: 1, failed: 0, tooLarge: 0 };
        }
      }),
      truthy(),
      catchError((error: unknown) => {
        if ((error as any).error?.status?.code === 1 && (error as any).error.status.message?.length > 0) {
          error = new Error((error as any).error.status.message);
        }

        console.error(error);

        let tooLarge = 0;

        if (error instanceof Error) {
          // captureException(error);
          this.dialogService.showError();
          tooLarge = error.message === this.ApiResponseStatus.payloadTooLarge ? 1 : 0;
        }

        return of({ progress: [1], uploaded: 0, failed: 1, tooLarge });
      }),
      startWith({ progress: [1], uploaded: 0, failed: 0, tooLarge: 0 }),
      takeUntil(this.destroy$)
    );
  }

  private lastLogout = 0;
  private logout$ = of(null).pipe(
    tap(() => this.auth.logout()),
    share()
  );

  private baseGet<T = any>(request: HttpGetRequest): Observable<ApiResponse<T>> {
    if (!('cacheDuration' in request)) {
      request.cacheDuration = request.path.includes('xref') ? -1 : 1000;
    }

    const cacheKey = this.cacheKey(request.path, request.params || {});
    const cacheResults = request.cacheDuration !== 0 && this.cache.includes(cacheKey);

    if (!cacheResults) {
      const resultSubject$ = new ReplaySubject<ApiResponse<T>>(1);
      let hasResults = false;

      if (!this.cache.get(cacheKey)) {
        this.cache.set(cacheKey, resultSubject$);
      }

      return this.http.get<T>(request).pipe(
        map(response => {
          if (!this.checkAuthTokenValid(response)) {
            this.cache.remove(cacheKey);
            return response;
          }

          if (!response.data) return response;
          if (request.path.includes('iframe/')) return response;

          //Fix these later references the fact that the json is nest in a list[] so you need to grab object index 0 instead of just getting the individual record.
          if (request.path.endsWith('jobPosting')) {
            // Fix these later
            response.data = (response.data as any).jobPostings[0];
            (response.data as any).header = (response.data as any).header[0];
            (response.data as any).desc = (response.data as any).desc[0];
          } else if (request.path.endsWith('jobPosting/header')) {
            // Fix these later
            response.data = (response.data as any).header[0];
          } else if (request.path.endsWith('recruiter/searchCandidates')) {
            // Fix these later
            response.data = (response.data as any).userSearchResults;
          } else if (request.path.endsWith('recruiter/watchlist')) {
            // Fix these later
            response.data = (response.data as any).watchlist;
          } else if (request.path.endsWith('recruiter/restrictedList')) {
            // Fix these later
            response.data = (response.data as any).restrictedlist;
          } else if (request.path.endsWith('candidate/analysisDetail')) {
            // Fix these later
            response.data = (response.data as any).candidate[0];
            // } else if (request.path.endsWith('email/interest')) { // Fix these later
            //   response.data = (response.data as any).profile;
          } else if (request.path.endsWith('email/more')) {
            // Fix these later
            response.data = (response.data as any).profile;
          }
          // else if (request.path.endsWith('email/decline')) {
          //   // Fix these later
          //   response.data = (response.data as any).profile;
          // }
          else if (request.path.endsWith('jobPosting/desc')) {
            // Fix these later
            response.data = (response.data as any).desc[0];
          }

          return response;
        }),
        tap(response => {
          resultSubject$.next(response);
          hasResults = true;

          if (request.cacheDuration && request.cacheDuration > 0) {
            timer(request.cacheDuration)
              .pipe(takeUntil(this.destroy$))
              .subscribe(() => {
                this.cache.remove(cacheKey);
              });
          }
        }),
        finalize(() => {
          if (!hasResults) {
            this.cache.remove(cacheKey);
          }
        }),
        catchError((error: unknown) => {
          this.cache.remove(cacheKey);
          throw error;
        }),
        takeUntil(this.destroy$)
      );
    } else {
      return this.cache.get<ReplaySubject<ApiResponse<T>>>(cacheKey);
    }
  }

  private basePost<T = any>(request: HttpPostRequest): Observable<ApiResponse<T>> {
    return this.http.post<T>(request).pipe(
      map(response => {
        if (!response.data) return response;
        if (request.path.includes('iframe/')) return response;

        if (request.path.endsWith('jobPosting/desc')) {
          // Fix these later
          response.data = (response.data as any).jobPostings[0];
          (response.data as any).header = (response.data as any).header[0];
          (response.data as any).desc = (response.data as any).desc[0];
        } else if (request.path.endsWith('jobPosting/header')) {
          // Fix these later
          response.data = (response.data as any).jobPostings[0];
          (response.data as any).header = (response.data as any).header[0];
          (response.data as any).desc = (response.data as any).desc[0];
        } else if (request.path.endsWith('model')) {
          // Fix these later
          response.data = (response.data as any).analysisModels[0];
        } else if (request.path.endsWith('jobPosting/createFromTemplate')) {
          // Fix these later
          response.data = (response.data as any).jobPostings[0];
        } else if (request.path.endsWith('xref/job-roles-raw')) {
          // Fix these later
          response.data = (response.data as any).jobRoles[0];
        }

        return response;
      }),
      takeUntil(this.destroy$)
    );
  }

  private checkAuthTokenValid(response: ApiResponse<any>) {
    return response.status.message !== this.ApiResponseStatus.invalidToken;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

/* T = the specified type of the data that is going to be returned by the api (example: DataTable<Item<T>>)
  this is passed to ApiResult from wherever ApiResult is defined as the return type */

/* R = request object of the type that is passed to ApiResult, extending HttpRequestBase
  if nothing is passed it defaults to HttpRequestBase
*/

export type ApiResult<T, R extends HttpRequestBase = HttpRequestBase> = ApiResultLoading<R> | ApiResultSuccess<T, R> | ApiResultError<R>;

export interface ApiResultLoading<R extends HttpRequestBase = HttpRequestBase> {
  finished: false;
  success: undefined;
  request: R;
}

export interface ApiResultSuccess<T, R extends HttpRequestBase = HttpRequestBase> {
  finished: true;
  success: true;
  data: T;
  request: R;
  response: ApiResponse<unknown>;
}

export interface ApiResultError<R extends HttpRequestBase = HttpRequestBase> {
  finished: true;
  success: false;
  error: { message: string };
  request: R;
  response?: ApiResponse<unknown>;
}

export interface ApiUploadResult {
  progress: number[];
  uploaded: number;
  failed: number;
  tooLarge: number;
}

export interface DataTable<T> {
  dataTable: T[];
  pagination: Pagination;
}

export interface Pagination {
  offset: number;
  numResults: number;
  totalEntries: number;
  cursor?: string;
}

export interface Item<T, O = any> {
  value: T;
  text: string;
  typeaheadText?: string;
  obj?: O;
  count?: number;
  company?: string;
  job?: string;
  id?: number;
}
