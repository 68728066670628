/* eslint-disable indent */
import { Component, Inject, OnDestroy, OnInit, Pipe, PipeTransform } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
 
import { saveAs } from 'file-saver';
 
import { Subject } from 'rxjs';
import { CandidatesService } from 'src/app/services/api/candidates.service';


@Component({
  selector: 'app-preview-resume',
  templateUrl: './preview-resume.component.html',
  styleUrls: ['./preview-resume.component.css']
})
export class PreviewResumeComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  allowVersions = true;
  fileUrl: any;
  selectedfileIndex = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<PreviewResumeComponent>, private sanitizer: DomSanitizer, public candidatesService: CandidatesService) {}

  ngOnInit() {
    this.allowVersions = this.data.allowVersions;
    this.selectFile(0);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getType(fileName: string) {
    const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);
    switch (fileExtension) {
      case 'docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case 'doc':
        return 'application/msword';
      case 'pdf':
        return 'application/pdf';
    }
  }

  selectFile(index: number) {
    this.selectedfileIndex = index;
    if (this.getType(this.data.versions[index].fileName) === 'application/pdf') {
      this.fileUrl = this.data.versions[index].url;
 
    } else {
      saveAs(this.data.versions[index].url, this.data.versions[index].fileName);
    }
 
  }
}

@Pipe({ name: 'safeUrl' })
export class SafeUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(html: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(html);
  }
}
