<ng-container>
  <div class="d-flex flex-row" mat-dialog-title>
    <div class="d-flex w-50"><label class="text-primary mt-2"> Preview resume </label></div>
    <div class="d-flex w-50 justify-content-end">
      <button mat-dialog-close mat-mini-fab color="primary" class="mt-2" style="width: 34px; height: 34px">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <mat-divider class="mat-background-gray-300"></mat-divider>

  <div class="mt-1" mat-dialog-content>
    <div class="h-100" style="overflow-y: hidden" fxLayout="column">
      <ng-container *ngIf="data.versions.length > 1">
        <nav mat-tab-nav-bar *ngIf="allowVersions">
          <a *ngFor="let file of data.versions; let i = index" mat-tab-link [active]="selectedfileIndex === i" (click)="selectFile(i)">
            <div class="d-flex flex-column">
              <span class="mat-small"> file :{{ file.fileName }} </span>
              <span class="hint">
                {{ file.dateCreated | date : 'medium' }}
              </span>
            </div>
          </a>
        </nav>
      </ng-container>
 
      <pdf-viewer [src]="fileUrl" [render-text]="true" [original-size]="false" style="height: 490px"></pdf-viewer>
    </div>
  </div>

  <mat-divider class="mat-background-gray-300"></mat-divider>

  <div class="d-flex justify-content-end" mat-dialog-actions>
    <button mat-stroked-button color="primary" mat-dialog-close>Close</button>
  </div>
</ng-container>
