import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { createSuccessResult } from 'src/app/utils/api-helpers';
import { successOnly, tapSuccess, switchMapSuccess, mapSuccessData } from 'src/app/utils/rxjs-operators';
import { ApiResult, ApiResultSuccess, ApiService, DataTable } from 'src/app/services/api.service';
import { Candidate } from 'src/app/services/api/candidates.service';
import { City, Region } from 'src/app/services/api/xref.service';

@Injectable({
  providedIn: 'root'
})
export class JobPostingsService implements OnDestroy {
  private destroy$ = new Subject<void>();
  public jobPostingOverride$ = new BehaviorSubject<boolean>(false);
  public jobPostingSearchCriteria$ = new BehaviorSubject<boolean>(false);
  public jobPostingDesc$ = new BehaviorSubject<boolean>(false);

  getRules = (jobId: number) =>
    this.api
      .get<ModelRule[]>({
        path: 'jobPosting/rules',
        params: { jobId }
      })
      .pipe(takeUntil(this.destroy$));

  getAllRules = () =>
    this.api
      .get<ModelRule[]>({
        path: 'jobPosting/allRules'
      })
      .pipe(takeUntil(this.destroy$));

  getEmailTemplates = (outreachStepId?: string, jobId?: number) =>
    this.api
      .get<emailTemplates[]>({
        path: 'campaign/email-templates',
        params: { outreachStepId, jobId }
      })
      .pipe(takeUntil(this.destroy$));

  saveHeader = (header: JobPostingHeader) =>
    this.api
      .post<JobPosting>({
        path: 'jobPosting/header',
        body: header
      })
      .pipe(
        tapSuccess(() => this.jobPostingOverride$.next(true)),
        takeUntil(this.destroy$)
      );

  saveHeaderBulk = (jobs: JobPostingHeader[]) =>
    this.api
      .post<void>({
        path: 'jobPosting/headerBulk',
        body: { jobs }
      })
      .pipe(takeUntil(this.destroy$));

  saveDesc = (desc: JobPostingDesc) =>
    this.api
      .post<JobPosting>({
        path: 'jobPosting/desc',
        body: desc
      })
      .pipe(
        tapSuccess(() => this.jobPostingDesc$.next(true)),
        takeUntil(this.destroy$)
      );

  saveSearchCriteria = (searchCriteria: JobPostingSearchCriteria) =>
    this.api
      .post<JobPostingSearchCriteria>({
        path: 'jobPosting/searchCriteria',
        body: searchCriteria
      })
      .pipe(
        switchMapSuccess(() => {
          return this.api
            .get<JobPosting>({
              path: 'jobPosting',
              params: { jobId: searchCriteria.jobId },
              cacheDuration: 0
            })
            .pipe(successOnly(), take(1));
        }),
        tapSuccess(() => this.jobPostingSearchCriteria$.next(true)),
        takeUntil(this.destroy$)
      );

  suggestedCriteria = (jobId: number) =>
    this.api
      .get<JobPostingSearchCriteria>({
        path: 'jobPosting/suggested-search',
        params: { jobId }
      })
      .pipe(takeUntil(this.destroy$));

  simpleSearch = (searchCriteria: JobPostingSearchCriteria) =>
    this.api
      .post<JobPostingSearchCriteria>({
        path: 'jobPosting/simpleSearch',
        body: searchCriteria
      })
      .pipe(
        switchMapSuccess(() => {
          return this.api
            .get<JobPostingSearchCriteria>({
              path: 'jobPosting/searchCriteria',
              params: { jobId: searchCriteria.jobId }
            })
            .pipe(successOnly(), take(1));
        }),
        tapSuccess(() => this.jobPostingSearchCriteria$.next(true)),
        takeUntil(this.destroy$)
      );

  advancedSearch = (searchCriteria: JobPostingSearchCriteria) =>
    this.api
      .post<JobPostingSearchCriteria>({
        path: 'jobPosting/advancedSearch',
        body: searchCriteria
      })
      .pipe(
        switchMapSuccess(() => {
          return this.api
            .get<JobPostingSearchCriteria>({
              path: 'jobPosting/searchCriteria',
              params: { jobId: searchCriteria.jobId }
            })
            .pipe(successOnly(), take(1));
        }),
        tapSuccess(() => this.jobPostingSearchCriteria$.next(true)),
        takeUntil(this.destroy$)
      );

  newJobPosting = (templateTypeId: number, templateId?: number) =>
    this.api
      .post<JobPostingSearchCriteria>({
        path: 'jobPosting/new',
        body: { templateTypeId, templateId }
      })
      .pipe(takeUntil(this.destroy$));

  updateFromTemplate = (jobId: number, templateId: string, templateTypeId: string) =>
    this.api
      .post<JobPosting>({
        path: 'jobPosting/updateFromTemplate',
        params: { templateId: jobId }, // TODO change this in backend lol
        body: {
          templateTypeId,
          templateId
        }
      })
      .pipe(
        tapSuccess(() => this.jobPostingOverride$.next(true)),
        takeUntil(this.destroy$)
      );

  resetAnalysis = (jobId: number) =>
    this.api
      .post<void>({
        path: 'jobPosting/resetAnalysis',
        params: { jobId }
      })
      .pipe(takeUntil(this.destroy$));

  convertToArchetype = (jobId: number, templateTypeId: number, searchName: string, archetypeId?: number) =>
    this.api
      .post<void>({
        path: 'archetype/convert',
        body: { jobId, templateTypeId, searchName, archetypeId }
      })
      .pipe(takeUntil(this.destroy$));

  updateArchetype = (archetypeId: number, isActive: number, searchName: string) =>
    this.api
      .post<void>({
        path: 'archetype',
        body: { archetypeId, isActive, searchName }
      })
      .pipe(takeUntil(this.destroy$));

  getJobs = (filters: Record<string, any> = {}, pagination = {}, cursor?: string) =>
    this.api
      .post<DataTable<JobPostingHeader>>({
        path: 'job-postings',
        params: { ...pagination },
        body: { ...filters, cursor }
      })
      .pipe(takeUntil(this.destroy$));

  getCounts = (filters: Record<string, any> = {}) =>
    this.api
      .post<Record<string, number>>({
        path: 'job-postings/counts',
        body: { ...filters }
      })
      .pipe(takeUntil(this.destroy$));

  getPriorityCounts = (jobId?: number, contextId = 1000) =>
    this.api
      .get<PriorityCounts[]>({
        path: 'jobPosting/priorities/counts',
        params: { jobId, contextId },
        cacheDuration: 0
      })
      .pipe(takeUntil(this.destroy$));

  getCandidatesCounts = (jobId?: number) =>
    this.api
      .get<Record<string, number>>({
        path: 'jobPosting/candidates/counts',
        params: { jobId },
        cacheDuration: 0
      })
      .pipe(takeUntil(this.destroy$));

  getCandidates = (body = {}, params = {}) =>
    this.api
      .post<DataTable<Candidate>>({
        path: 'jobPosting/candidates',
        params,
        body
      })
      .pipe(takeUntil(this.destroy$));

  getCandidatesShortList = (params = {}) =>
    this.api
      .get<DataTable<Candidate>>({
        path: 'jobPosting/shortList',
        params
      })
      .pipe(takeUntil(this.destroy$));

  getCandidatesArchiveList = (params = {}) =>
    this.api
      .get<DataTable<Candidate>>({
        path: 'jobPosting/archive-list',
        params
      })
      .pipe(takeUntil(this.destroy$));

  getSourcedByCounts = (jobId?: number) =>
    this.api
      .get<SourcedByCounts[]>({
        path: 'jobPosting/sourcedBy/counts',
        params: { jobId },
        cacheDuration: 0
      })
      .pipe(takeUntil(this.destroy$));

  getCandidatesProgressCounts = (jobId?: number, sourcedMethodId?: string) =>
    this.api
      .get<any>({
        path: 'jobPosting/progress/counts',
        params: { jobId, sourcedMethodId },
        cacheDuration: 0
      })
      .pipe(takeUntil(this.destroy$));

  getCandidatesProgress = (body = {}, params = {}) =>
    this.api
      .post<DataTable<any>>({
        path: 'jobPosting/progress',
        params,
        body
      })
      .pipe(takeUntil(this.destroy$));

  getCampaignCandidateCounts = (jobId?: number) =>
    this.api
      .post<CampaignCandidateCounts[]>({
        path: 'campaign/counts',
        params: { jobId },
        cacheDuration: 0
      })
      .pipe(takeUntil(this.destroy$));

  getCandidatesAnalytics = (jobId: number) =>
    this.api
      .get<Analytics>({
        path: 'jobPosting/analytics',
        params: { jobId }
      })
      .pipe(takeUntil(this.destroy$));

  getCollaborateToken = (jobId: number) =>
    this.api
      .get<{ token: string }>({
        path: 'jobPosting/collaborate',
        params: { jobId }
      })
      .pipe(takeUntil(this.destroy$));

  getRoleArchetypes = (searchValue?: string, templateTypeId?: number) =>
    this.api
      .get<any>({
        path: 'archetype',
        params: { searchValue, templateTypeId },
        cacheDuration: 0
      })
      .pipe(takeUntil(this.destroy$));

  saveRoleArchetype = (jobId: number, archetypeId: number) =>
    this.api
      .post<void>({
        path: 'jobPosting/archetype',
        params: { jobId, archetypeId }
      })
      .pipe(
        switchMapSuccess(() => {
          return this.api
            .get<JobPosting>({
              path: 'jobPosting',
              params: { jobId },
              cacheDuration: 0
            })
            .pipe(successOnly(), take(1));
        }),
        tapSuccess(() => this.jobPostingOverride$.next(true)),
        takeUntil(this.destroy$)
      );

  jobSearchFilters = (): Observable<ApiResult<Filter[]>> => {
    return new Observable(subscriber => {
      const results: ApiResultSuccess<Filter[]> = createSuccessResult([
        { filterId: 1, filterDesc: 'Archetype' },
        { filterId: 2, filterDesc: 'Saved Job Search' },
        { filterId: 3, filterDesc: 'New' }
      ]);
      subscriber.next(results);
    });
  };

  jobSearchArchetypeFilters = (): Observable<ApiResult<Filter[]>> => {
    return new Observable(subscriber => {
      const results: ApiResultSuccess<Filter[]> = createSuccessResult([
        { filterId: 1, filterDesc: 'Angular Developer' },
        { filterId: 2, filterDesc: 'Director' },
        { filterId: 3, filterDesc: 'Designer' }
      ]);
      subscriber.next(results);
    });
  };

  getProjectHeader = (jobId: number) =>
    this.api
      .get<JobPostingHeader>({
        path: 'jobPosting/header',
        params: { jobId }
      })
      .pipe(takeUntil(this.destroy$));

  getCandidatesStageeCounts = (jobId?: number, sourcedMethodId?: string) =>
    this.api
      .get<stageCount[]>({
        path: 'jobPosting/progress/counts',
        params: { jobId, sourcedMethodId },
        cacheDuration: 0
      })
      .pipe(takeUntil(this.destroy$));

  getDesc = (jobId?: number) =>
    this.api
      .get<JobPostingDesc>({
        path: 'jobPosting/desc',
        params: { jobId }
      })
      .pipe(takeUntil(this.destroy$));

  getsearchCriteria = (jobId?: number) =>
    this.api
      .get<SearchCriteria>({
        path: 'jobPosting/searchCriteria',
        params: { jobId }
      })
      .pipe(
        mapSuccessData(data => {
          data.searchCriteria.jobId = data.jobId;
          return data.searchCriteria;
        }),
        takeUntil(this.destroy$)
      );

  getJobsSearch = (searchValue?: string) =>
    this.api
      .post<DataTable<JobPostingHeader>>({
        path: 'job-postings',

        body: { searchValue, viewId: 1000 }
      })
      .pipe(takeUntil(this.destroy$));

  getFilterData = (dataPath: string, stagesIds: any[], jobId?: number, searchValue?: string) =>
    this.api
      .post<any>({
        path: 'filters/' + dataPath,
        params: { jobId, searchValue },
        body: { stagesIds }
      })
      .pipe(takeUntil(this.destroy$));

  constructor(private api: ApiService) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

export interface JobPosting {
  groupId: string;
  jobId: number;
  userId: number;
  recruiterId: string;
  header: JobPostingHeader;
  desc: JobPostingDesc;
  activityNotes: any[];
  notes: any[];
  searchCriteria: JobPostingSearchCriteria;
  isActive: '0' | '1';

  jobTitle: string;
  jobPostingStatusId: string;
  jobPostingStatusDesc: string;
  companyName: string;
  companyLocation?: string;
  numCandidates: string;
  assignedToId: string;
  assignedTo: string;
}

export interface JobPostingHeader {
  jobId: number;
  modelId?: string;
  modelTemplateId: string;
  isJobTemplate: '0' | '1';
  groupId: string;
  groupName: string;
  jobTitle: string;
  assignedToId: string;
  assignedToName: string;
  jobPostingStatusId: string;
  jobPostingStatusDesc: string;
  companyId?: string;
  companyName?: string;
  locationGeoId?: string;
  locationGeoDisplay: string;
  lastUpdated: string;
  isActive: '0' | '1';
}

export interface JobPostingDesc {
  jobPostingRawText: string;
  jobDescRich?: string;
}
export interface JobPostingSearchCriteria {
  jobId: number;
  isAdvancedSearch?: boolean;
  certifications: JobPostingSearchCriteriaItem<ItemGroup[]>;
  companies: JobPostingSearchCriteriaItem<ItemGroup[]>;
  degreeTypes: JobPostingSearchCriteriaItem<ItemGroup[]>;
  experienceReqs: JobPostingSearchCriteriaItem<ItemGroup[]>;
  industries: JobPostingSearchCriteriaItem<ItemGroup[]>;
  jobRoleLevels: JobPostingSearchCriteriaItem<ItemGroup[]>;
  jobRoles: JobPostingSearchCriteriaItem<ItemGroup[]>;
  jobSubFamilies: JobPostingSearchCriteriaItem<ItemGroup[]>;
  jurisdiction: JobPostingSearchCriteriaItem<Jurisdiction[]>;
  positionTypes: JobPostingSearchCriteriaItem<ItemGroup[]>;
  preferredMajors: JobPostingSearchCriteriaItem<ItemGroup[]>;
  skills: JobPostingSearchCriteriaItem<ItemGroup[]>;
  workStatuses: JobPostingSearchCriteriaItem<ItemGroup[]>;
  dataSources: JobPostingSearchCriteriaItem<ItemGroup[]>;
  payBands: JobPostingSearchCriteriaItem<ItemGroup[]>;
}

export interface SearchCriteria {
  jobId: number;
  searchCriteria: JobPostingSearchCriteria;
}

export interface JobPostingSearchCriteriaItem<T> {
  groups: T;
  rule?: {
    ruleWeightId: string;
    ruleWeightDesc: string;
  };
  isExpanded: boolean;
}

interface ItemGroup {
  items: any[];
  attributes?: ItemGroupAttributes;
}

interface ItemGroupAttributes {
  expMin?: string;
  expMax?: string;
  cntMin?: string;
  cntMax?: string;
  lastUsedId?: number;
  optId?: number;
  ruleWeightId?: number;
  ruleWeightDesc?: string;
  expBands?: string[];
  minPayRate?: number;
  maxPayRate?: number;
}

export interface Jurisdiction {
  countries?: any[];
  regions?: Region[];
  cities?: City[];
  zipCodes?: any[];
  searchRadius?: any[];
  distance?: any[];
}

export interface SuggestedCriteria {
  displayText: string;
  isRequired: '0' | '1';
  json: ItemGroup;
  key: keyof JobPostingSearchCriteria;
}

export interface ModelRule {
  modelId: number;
  ruleId: number;
  ruleName: string;
  ruleDescription: string;
  ruleWeightId: number;
  weight: number;
  ruleWeightDesc: string;
  wasWeightChanged: 0 | 1;
  isDirty: 0 | 1;
  maxValue: number;
  ruleCategoryId: number;
  ruleCategory: string;
  isFitRule: 0 | 1;
  needsRecalc: 0 | 1;
  key: keyof JobPostingSearchCriteria;
  dataType: 'typeahead' | 'dropdown' | 'location' | 'checkbox';
  groupLevelWeight: number;
  multipleGroups: 0 | 1;
  hasCounter: 0 | 1;
  hasLastUsed: 0 | 1;
  hasMinMax: 0 | 1;
  isSimpleSearch: boolean;
  isAdvancedSearch: boolean;
}

interface Analytics {
  stagesBySource: (Record<string, number> & Record<'source', string>)[];
  engagement?: {
    avgResponseTime: number | null;
    numNewContacts: number;
    numRemindersSent: number;
    numResponseInterested: number;
    numResponseNotInterested: number;
  };
  responseHistory: {
    daysSinceFirstContact: number;
    numCandidates: number;
  }[];
}

export interface JobArchetype {
  archetypeId: number;
  jobTitle: string;
}

export interface Filter {
  filterId: number;
  filterDesc: string;
}

export interface PriorityCounts {
  priorityId: number;
  priorityDesc: string;
  sort: number;
  cnt: number;
}

export interface SourcedByCounts {
  sourcedMethodId: number;
  methodDesc: string;
  cnt: number;
  sort: number;
}

export interface CampaignCandidateCounts {
  campaignId: number;
  campaignDesc: string;
  cnt: number;
  sort: number;
  campaignStatusId: number;
}

export interface emailTemplates {
  templateTypeId: number;
  templateName: string;
  templateDesc: string;
  isActive: number;
}

export interface emailTemplate {
  emailBody?: string;
  emailHeader?: string;
  isActive?: number;
}

export interface stageCount {
  cnt: number;
  stageId: number;
  stageDesc: string;
  sort: number;
  stageGroupId: number;
  stageGroupDesc: string;
}

export interface Priority {
  priorityId: number;
  priorityDesc: string;
}

export interface stage {
  stageId: number;
  stageDesc: string;
}

export interface Source {
  sourceId: number;
  sourceDesc: string;
}
