import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { InputSelectModule } from '../input-select/input-select.module';
import { PaginatorModule } from '../paginator/paginator.module';
import { ApiResultsMergePipeModule } from 'src/app/pipes/api-results-merge.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NotificationComponent } from './notification/notification.component';

@NgModule({
  imports: [MatIconModule, MatDialogModule, MatButtonModule, MatDividerModule],
  declarations: [NotificationComponent],
  exports: [
    CommonModule,
    MatButtonModule,
    FlexLayoutModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTabsModule,
    InputSelectModule,
    PaginatorModule,
    ApiResultsMergePipeModule,
    MatTooltipModule,
    MatDialogModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    TextFieldModule,
    NotificationComponent
  ]
})
export class SharedModule { }
