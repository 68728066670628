<ng-container *ngIf="{
    tasks: tasks$ | async,
    taskFilter: taskFilter$ | async,
    taskFilters: taskFilters$ | async
  } as results">

  <div style="width: 400px;" fxLayout="column" fxFlex="grow" *ngIf="sidebar">
    <ng-container *ngTemplateOutlet="contentControl">
    </ng-container>
  </div>

  <div class="w-100" fxLayout="column" fxFlex="grow" *ngIf="!sidebar">
    <ng-container *ngTemplateOutlet="contentControl">
    </ng-container>
  </div>

  <ng-template #contentControl>
    <div class="w-100 p-3 mat-background-primary mat-color-primary-contrast" fxLayout="row" fxLayoutAlign="start center"
      *ngIf="header">
      <button mat-icon-button (click)="closeSidebar()">
        <mat-icon>arrow_forward</mat-icon>
      </button>

      <div class="ms-2" fxLayout="column" fxLayoutAlign="center start" fxFlex="grow">
        <div fxLayout="row" fxLayoutAlign="start center">
          <h3 class="m-0 no-wrap">Tasks</h3>

          <ng-container *ngIf="results.taskFilters && results.taskFilters.success">
            <button mat-icon-button class="ms-2 default-size" [matMenuTriggerFor]="taskMenu">
              <mat-icon>keyboard_arrow_down</mat-icon>
            </button>

            <mat-menu #taskMenu>
              <button mat-menu-item *ngFor="let item of results.taskFilters.data" (click)="taskFilter$.next(item)">
                {{item.filterDesc}}
              </button>
            </mat-menu>
          </ng-container>
        </div>

        <span class="mat-small" *ngIf="results.taskFilter && results.taskFilter.filterDesc">
          Showing {{ results.taskFilter.filterDesc }}
        </span>
      </div>

      <button mat-icon-button (click)="taskDialog('New Task')">
        <mat-icon>add</mat-icon>
      </button>

      <mat-divider></mat-divider>
    </div>

    <div class="w-100 p-3" fxLayout="row" fxLayoutAlign="start center" *ngIf="!header && filter">
      <button mat-icon-button (click)="taskDialog('New Task')">
        <mat-icon>add</mat-icon>
      </button>

      <span class="spacer"></span>

      <span class="mat-small" *ngIf="results.taskFilter && results.taskFilter.filterDesc">
        Showing {{ results.taskFilter.filterDesc }}
      </span>

      <ng-container *ngIf="results.taskFilters && results.taskFilters.success">
        <button mat-icon-button class="ms-2" [matMenuTriggerFor]="taskMenu">
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>

        <mat-menu #taskMenu>
          <button mat-menu-item *ngFor="let item of results.taskFilters.data" (click)="taskFilter$.next(item)">
            {{item.filterDesc}}
          </button>
        </mat-menu>
      </ng-container>

      <mat-divider></mat-divider>
    </div>

    <ng-container *ngTemplateOutlet="listControl">
    </ng-container>
  </ng-template>

  <ng-template #listControl>
    <div class="w-100 h-0 p-relative" fxLayout="column" fxFlex="grow" *ngIf="results.tasks">
      <div class="full-shade" *ngIf="!results.tasks.finished">
        <mat-spinner color="accent"></mat-spinner>
      </div>

      <ng-container *ngIf="results.tasks.success">
        <div class="middle" *ngIf="results.tasks.data.dataTable.length === 0">
          There are no tasks
        </div>

        <ng-container *ngIf="results.tasks.data.dataTable.length > 0">
          <div class="scroll-y" fxFlex="grow">
            <ng-container *ngFor="let fromNow of results.tasks.data.dataTable | fromNow | pluck: 'fromNow' | unique">
              <h4 class="p-3 m-0">{{fromNow | titlecase}}</h4>

              <mat-divider></mat-divider>

              <ng-container
                *ngFor="let item of results.tasks.data.dataTable | fromNow | filterBy: ['fromNow']: fromNow | pluck: 'obj'">
                <div class="p-3 c-pointer hover-row" fxLayout="row" fxLayoutAlign="start center"
                  (click)="viewTask(item)">
                  <div class="w-100" fxLayout="row" fxFlex="grow" fxLayoutAlign="space-between center">
                    <div class="px-3 w-0" fxFlex="grow" fxLayout="column">
                      <span class="my-0 ellipsis" fxFlex="grow">{{ item.title }}</span>
                      <span class="hint mat-small ellipsis" fxFlex="grow">
                        {{ item.taskDueDateEpoch * 1000 | date: 'mediumDate' }}
                        {{ item.taskDueDateEpoch * 1000 | date: 'shortTime' }}
                      </span>
                    </div>
                  </div>

                  <button mat-icon-button (click)="taskDialog('Edit Task', item); $event.stopPropagation();"
                    *ngIf="item.isOwner === 1">
                    <mat-icon>edit</mat-icon>
                  </button>

                  <button mat-icon-button (click)="saveTask(item, { isActive: 0 }); $event.stopPropagation();"
                    *ngIf="item.isOwner === 1">
                    <mat-icon>delete_outline</mat-icon>
                  </button>

                  <button mat-icon-button (click)="saveTask(item, { isCompleted: 1 }); $event.stopPropagation();">
                    <mat-icon>done</mat-icon>
                  </button>
                </div>

                <mat-divider></mat-divider>
              </ng-container>
            </ng-container>
          </div>

          <app-paginator [pageIndex]="results.tasks.data.pagination.offset / results.tasks.data.pagination.numResults"
            [pageSize]="results.tasks.data.pagination.numResults" [length]="results.tasks.data.pagination.totalEntries"
            (page)="changePage($event)">
          </app-paginator>
        </ng-container>
      </ng-container>
    </div>
  </ng-template>

</ng-container>