import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { ContextService } from 'src/app/services/context.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnDestroy {
  private destroy$ = new Subject<void>();

  navItems = [
    //   {
    //   icon: 'home',
    //   name: 'Dashboard',
    //   url: '/dashboard',
    //   newWindow: false,
    //   seat: 'coreApp' as const
    // },
    {
      icon: 'home',
      name: 'Jobs',
      url: '/jobs',
      newWindow: false,
      seat: 'coreApp' as const
    }
    // {
    //   icon: 'recent_actors',
    //   name: 'Candidates',
    //   url: '/candidates',
    //   newWindow: false,
    //   guard: true
    // },
    //{
    //   icon: 'star_outline',
    //   name: 'Watch List',
    //   url: '/watch-list',
    //   newWindow: false,
    //   seat: 'coreApp' as const
    // }, {
    //   icon: 'settings',
    //   name: 'Settings',
    //   url: '/admin',
    //   newWindow: true,
    //   isAdmin: true
    // }
  ];

  constructor(
    private context: ContextService,

    private router: Router
  ) {
    this.context.headerIconSet = 'home';
    this.context.navItems = this.navItems.map(x => ({
      ...x
      // url: this.router.createUrlTree([x.url]).toString()
    }));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
