<ng-container *ngIf="{ value: items$ | async } as items">
  <div class="input-group">
    <!--optional, renders an icon in the left side of the input if the component passes one-->
    <ng-container *ngIf="iconPrefix">
      <span class="material-symbols-outlined input-group-text" [ngClass]="iconPrefix ? 'input-group-text__prefix' : 'input-group-text'">
        {{ iconPrefix }}
      </span>
    </ng-container>

    <!--renders a text field with an autocomplete that shows while the user is typing and goes away when the user clicks an option-->
    <input
      #inputRef
      [type]="inputType"
      [class]="getInputClass()"
      [value]="text || ''"
      [placeholder]="placeholder"
      [disabled]="disabled"
      (input)="searchText$.next(inputRef.value)"
      (focus)="focus()"
      (blur)="blur()"
      [matAutocomplete]="auto"
      [matAutocompleteDisabled]="!api" />

    <!--optional, renders an icon in the right side of the input if the component passes one-->
    <ng-container *ngIf="iconSuffix">
      <span class="material-symbols-outlined input-group-text" [ngClass]="iconSuffix ? 'input-group-text__suffix' : 'input-group-text'">
        <!--display a spinner while autocomplete is searching for results-->
        <span *ngIf="items.value && items.value.finished === false">
          <div class="spinner-border" role="status"></div>
        </span>
        <!--display a search icon when the spinner is inactive-->
        <ng-container *ngIf="iconSuffix">
          <span *ngIf="!items.value || (items.value && items.value.finished !== false)">{{ iconSuffix }}</span>
        </ng-container>
      </span>
    </ng-container>
  </div>

  <!--renders the autocomplete attached to the input-->
  <mat-autocomplete #auto [displayWith]="displayFn" (optionSelected)="setItem($event.option.value)">
    <ng-container *ngIf="items.value && items.value.success">
      <mat-option *ngFor="let item of items.value.data" [value]="item" (mousedown)="$event.preventDefault()">
        {{ item.typeaheadText || item.text }}
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</ng-container>
