import { Injectable, OnDestroy } from '@angular/core';
import { ApiService } from '../api.service';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { mapSuccessData, tapSuccess } from 'src/app/utils/rxjs-operators';
import { emailTemplate } from './job-postings.service';

@Injectable({
  providedIn: 'root'
})
export class CampaignService implements OnDestroy {
  private destroy$ = new Subject<void>();
  public refreshCampaign$ = new BehaviorSubject<any>(null);
  public refreshTemplates$ = new BehaviorSubject<any>(null);

  constructor(private api: ApiService) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getCampaignStatus = (jobId: number) =>
    this.api
      .get<CampaignStatus[]>({
        params: { jobId },
        path: 'campaign/status'
      })
      .pipe(takeUntil(this.destroy$));

  updateCampaignSettings = (body: any, jobId: any) =>
    this.api
      .post<void>({
        path: 'campaign/settings',
        params: { jobId },
        body: { ...body }
      })
      .pipe(takeUntil(this.destroy$));

  getCampaignSettings = (jobId: number) =>
    this.api
      .get<CampaignSettings[]>({
        params: { jobId },
        path: 'campaign/settings'
      })
      .pipe(takeUntil(this.destroy$));

  updateCampaignStatus = (body: CampaignStatusBulk) =>
    this.api
      .post<void>({
        path: 'campaign/status',
        body: { ...body }
      })
      .pipe(
        tapSuccess(() => this.refreshCampaign$.next(true)),
        takeUntil(this.destroy$)
      );

  getEmailTemplate = (jobId: number, templateTypeId: string) =>
    this.api
      .get<emailTemplate[]>({
        path: 'campaign/email-template',
        params: { jobId, templateTypeId }
      })
      .pipe(
        mapSuccessData(data => data[0]),

        takeUntil(this.destroy$)
      );

  updateEmailTemplate = (jobId: number, templateTypeId: number, body: emailTemplate) =>
    this.api
      .post<void>({
        path: 'campaign/email-template',
        params: { jobId, templateTypeId },
        body: { ...body }
      })
      .pipe(
        tapSuccess(() => this.refreshTemplates$.next(true)),
        takeUntil(this.destroy$)
      );
}

export interface CampaignSettings {
  jobPostingId: number;
  useDynamicTemplates: number;
  allowUseProfessionalEmail: number;
}

export interface CampaignStatus {
  campaignStatusId: number;
  campaignId: number;
  campaignDesc: string;
}

export interface CampaignStatusBulk {
  jobId: number;
  campaigns: {
    campaignId: number;
    campaignStatusId: number;
  }[];
}
