import { CandidatesService, Phone } from 'src/app/services/api/candidates.service';
import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject, of, share, startWith, switchMap, takeUntil } from 'rxjs';
import { Email } from 'src/app/services/api/candidates.service';
import { XrefService } from 'src/app/services/api/xref.service';
import { createSuccessResult } from 'src/app/utils/api-helpers';
import { successData } from 'src/app/utils/rxjs-operators';


@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html'
})
export class ContactInfoComponent implements OnDestroy {
  private destroy$ = new Subject<void>();

  emailTypes$ = this.xref.getEmailTypes().pipe(
    successData(),
    takeUntil(this.destroy$)
  );
  phones: Phone[] = [];
  emails: Email[] = [];

  loading = false;
  emailRegex = /^\S+@\S+\.\S+$/;
  phoneNumberRegex = /^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ContactInfoComponent>,
    private xref: XrefService,
    private candidateService: CandidatesService,
    private snackBar: MatSnackBar

  ) {
    this.phones = (JSON.parse(JSON.stringify(data.phones))).map((x: Phone) => { return { ...x, isValid: 1, valid: 1 }; });
    this.emails = JSON.parse(JSON.stringify(data.emails)).map((x: Email) => { return { ...x, isValid: 1, valid: 1 }; });
  }


  deletePhoneNumber(index: number) {
    if (this.phones[index].contactId)
      this.phones[index].isValid = 0;
    else
      this.phones.splice(index, 1);
  }

  deleteEmail(index: number) {

    if (this.emails[index].emailId)
      this.emails[index].isValid = 0;
    else
      this.emails.splice(index, 1);
  }

  addEmail() {
    this.emails.push({ email: '', emailTypeId: 0, isPrimary: 0, isValid: 1, grade: 'A' });
  }

  addPhoneNumber() {
    this.phones.push({ phoneNumber: '', isPrimary: 0, isValid: 1, contactType: 1 });
  }

  emailValueChange(index: number, email: any) {
    if (this.emailRegex.test(email)) {

      this.emails[index].valid = 1;
    }
    else this.emails[index].valid = 0;
  }

  phoneNumberValueChange(index: number, number: any) {
    if (number.length < 15) {
      if (this.phoneNumberRegex.test(number)) {
        this.phones[index].valid = 1;
      }
      else this.phones[index].valid = 0;
    }

  }

  save() {

    this.loading = true;

    of(null).pipe(
      switchMap(() => this.candidateService.updateContactInfo({
        profileRef: this.data.profileRef,
        emails: this.emails.filter((x: any) => x.valid === 1),
        phones: this.phones.filter((x: any) => x.valid === 1)
      })),
      switchMap(result => {
        if (result.finished) {
          this.loading = false;
          const successMessage = result.success ? 'Contact info has been updated.' : 'An error occurred, please try again later.';
          this.snackBar.open(successMessage, 'Close', { duration: 5 * 1000 });
          if (result.success) this.dialogRef.close(result.response?.data);
        }
        return of(result);
      }),
      share(),
      startWith(createSuccessResult(undefined)),
      takeUntil(this.destroy$)
    ).subscribe();

  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  handlePrimaryEmail(email: Email, index: number) {
    if (email.isPrimary) {
      this.emails.forEach((element: Email, i: number) => {
        element.isPrimary = i !== index ? 0 : 1;
      });
    }
  }

  handlePrimaryPhone(email: Phone, index: number) {
    if (email.isPrimary) {
      this.phones.forEach((element: Phone, i: number) => {
        element.isPrimary = i !== index ? 0 : 1;
      });
    }
  }
}
