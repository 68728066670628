import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ApiService } from './../api.service';
import { Candidate } from './candidates.service';
import { mapSuccessData } from 'src/app/utils/rxjs-operators';

@Injectable({
  providedIn: 'root'
})
export class EmailsService implements OnDestroy {
  private destroy$ = new Subject<void>();

  send = (candidates: Candidate[]) =>
    this.api
      .post<void>({
        path: 'email',
        body: { jobId: candidates[0].jobId, profiles: candidates.map(x => x.profileRef) }
      })
      .pipe(takeUntil(this.destroy$));

  preview = (jobId: number, previewBody: PreviewBody, profileref?: string) =>
    this.api
      .post<PreviewResponse>({
        path: 'email/preview',
        params: { jobId, profileref },
        body: previewBody
      })
      .pipe(takeUntil(this.destroy$));

  sendEmail = (jobId: number, sendBody: SendBody) =>
    this.api
      .post<any>({
        path: 'email/send',
        params: { jobId },
        body: sendBody
      })
      .pipe(takeUntil(this.destroy$));

  getRecruiterEmail = () =>
    this.api
      .get<userEmail[]>({
        path: 'recruiter/user-email',
        cacheDuration: 0
      })
      .pipe(
        mapSuccessData(data => data[0]),
        takeUntil(this.destroy$)
      );

  emailList = (requestBody = {}) =>
    this.api
      .post<emailList[]>({
        path: 'email/list',
        body: requestBody
      })
      .pipe(takeUntil(this.destroy$));

  candidateEmailThreads = (profileRef: string) =>
    this.api
      .get<EmailThread[]>({
        path: 'email/threads',
        params: { profileRef }
      })
      .pipe(takeUntil(this.destroy$));

  emailDetails = (emailId: number) =>
    this.api
      .get<EmaiDetails[]>({
        path: 'email',
        params: { emailId }
      })
      .pipe(
        mapSuccessData(data => data[0]),
        takeUntil(this.destroy$)
      );

  constructor(private api: ApiService) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

export interface PreviewBody {
  emailHeader: string;
  emailBody: string;
}
export interface PreviewResponse {
  emailHeader: string;
  emailBody: string;
  fromAddress: Address;
  toAddress: Address;
}

export interface SendBody {
  emailHeader: string;
  emailBody: string;
  candidates?: string[];
}
export interface userEmail {
  userName: string;
}

export interface Address {
  DisplayName: string;
  User: string;
  Host: string;
  Address: string;
}

export interface emailList {
  profileRef: string;
  displayName: string;
  email: any;
}

export interface EmailThread {
  profileRef: string;
  emailId: number;
  subject: string;
  sentDate: string;
  summary: string;
  messagePreview: string;
  emailThreadId: string;
}

export interface EmaiDetails {
  profileRef: string;
  emailId: number;
  subject: string;
  sentDate: string;
  summary: string;
  messagePreview: string;
  emailThreadId: string;
  fromAddress: string;
  toAddress: string;
  messageBody: string;
  jobTitle: string;
}
