import { MatSnackBar } from '@angular/material/snack-bar';
import { Candidate } from '../services/api/candidates.service';

export function checkForRestrictedCandidates(candidates: Candidate[], snackBar: MatSnackBar) {
  const restrictedCandidates = candidates.filter(candidate => candidate.isRestricted);

  if (restrictedCandidates.length === 1) {
    snackBar.open(`${restrictedCandidates[0].displayName} is restricted and will receive any communication sent. Please verify before proceeding.`, 'Close', { duration: 5000 });
  }

  if (restrictedCandidates.length > 1) {
    snackBar.open('Some candidates are restricted and will receive any communication sent. Please verify before proceeding.', 'Close', { duration: 5000 });
  }
}
