import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { merge, Subject, combineLatest } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSidenav } from '@angular/material/sidenav';

import { truthy } from 'src/app/utils/rxjs-operators';

import { AuthService } from 'src/app/services/auth.service';
import { CandidatesService } from 'src/app/services/api/candidates.service';
import { ContextService } from 'src/app/services/context.service';
import { DialogService } from './services/dialog.service';
import { IframeService } from './services/iframe.service';
import { NavService } from 'src/app/services/nav.service';
import { NotesService } from 'src/app/services/api/notes.service';
import { TasksService } from 'src/app/services/api/tasks.service';
import { BnNgIdleService } from 'bn-ng-idle';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  private destroy$ = new Subject<void>();

  private routesToHideSidebar = [
    '/intake-form'
  ];

  showHiddenNav = false;
  checkingAccess = false;
  noAccess = false;
  hideSidebar = false;

  rightSideBar: SideBarView = '';
  @ViewChild('rightSidebar') rightSidebar?: MatSidenav;

  window = window;

  constructor(
    public auth: AuthService,
    private candidates: CandidatesService,
    public context: ContextService,
    private dialog: DialogService,
    public iframe: IframeService,
    public nav: NavService,
    private notes: NotesService,
    private tasks: TasksService,

    public activatedRoute: ActivatedRoute,
    private location: Location,
    public router: Router,

    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private bnIdle: BnNgIdleService
  ) {
    this.iconRegistry.addSvgIconLiteral('chrome_app', this.sanitizer.bypassSecurityTrustHtml(chromeAppIcon));
    this.iconRegistry.addSvgIconLiteral('indeed', this.sanitizer.bypassSecurityTrustHtml(indeedIcon));
    this.iconRegistry.addSvgIconLiteral('nlp', this.sanitizer.bypassSecurityTrustHtml(nlpIcon));
    this.iconRegistry.addSvgIconLiteral('redis', this.sanitizer.bypassSecurityTrustHtml(redisIcon));
    this.iconRegistry.addSvgIconLiteral('remove_business', this.sanitizer.bypassSecurityTrustHtml(removeBusiness));
    this.iconRegistry.addSvgIconLiteral('remove_recent_actors', this.sanitizer.bypassSecurityTrustHtml(removeRecentActors));

    this.router.events.pipe(
      filter((event): event is NavigationStart => !!event && event instanceof NavigationStart),
      filter(() => !this.nav.hasLoadedOnce),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.nav.params = {};
    });

    combineLatest([
      this.router.events.pipe(
        filter((event): event is NavigationStart => !!event && event instanceof NavigationStart)
      ),
      this.auth.userInfo$
    ]).pipe(
      takeUntil(this.destroy$)
    ).subscribe(([route, _userInfo]) => {
      this.checkingAccess = false;

      const landingPage = this.auth.landingPage;
      //console.log('landingPage' + landingPage);
      this.hideSidebar = false;
      if (this.routesToHideSidebar.find(x => route.url.startsWith(x))) {
        this.hideSidebar = true;
      }

      if (route.url === '/') {
        if (landingPage !== '/') {

          void this.router.navigateByUrl(landingPage, { skipLocationChange: true });
          this.location.replaceState(landingPage);
        } else {

          this.noAccess = true;
        }
      }
    });

    merge(
      this.candidates.sidebarShow$,
      this.candidates.sidebarShowTable$
    ).pipe(
      truthy(),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.showRightSidebar('candidate');
    });

    this.notes.sidebarShow$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.showRightSidebar('notes');
    });

    this.tasks.sidebarShow$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.showRightSidebar('tasks');
    });

    merge(
      this.candidates.sidebarClose$,
      this.notes.sidebarClose$,
      this.tasks.sidebarClose$
    ).pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      if (this.rightSidebar) {
        void this.rightSidebar.close();
      }
    });

    this.bnIdle.startWatching(15 * 60).pipe(
      takeUntil(this.destroy$)
    ).subscribe(res => {
      if (res) {
        // session expired;
        const currentUrl = this.router.url;
        void this.router.navigateByUrl('refresh', { skipLocationChange: true })
          .then(() =>
            this.router.navigateByUrl(currentUrl).then()
          );
      }
    });
  }

  uploadResumes() {
    this.dialog.uploadResumes({
      dialog: { title: this.context.latest.profileRef ? 'Upload Resume' : 'Upload Resumes' },
      item: { profileRef: this.context.latest.profileRef }
    }).pipe(
      take(1),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  showRightSidebar(x: SideBarView) {
    if (this.rightSideBar !== x) {
      this.rightSideBar = x;

      void this.rightSidebar?.open();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

type SideBarView = '' | 'notes' | 'tasks' | 'candidate';

const chromeAppIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="2225.3" height="1921.9" viewBox="0 0 2225.3 1921.9">
  <g>
    <path fill="#EEEEEE" d="M1365.5,404.7H859.8c-55.9,0-101.2-45.3-101.2-101.2c0-55.8,45.3-101.1,101.2-101.1h505.7c55.9,0,101.2,45.3,101.2,101.1C1466.7,359.4,1421.4,404.7,1365.5,404.7z M0,0v1770.2c0,83.4,68.3,151.7,151.7,151.7h1921.9c83.4,0,151.7-68.3,151.7-151.7V0H0z"/>
    <path fill="#DB4437" d="M1112.7,809.2c-339.2,0-637.3,175.7-808.3,441.1v671.5h468.5l339.8-588.6h856.1C1809.7,1022.2,1486,809.2,1112.7,809.2z"/>
    <path fill="#0F9D58" d="M304.1,1250.7c-96.4,149.8-152.4,328.1-152.4,519.5c0,51.6,4.1,102.3,12,151.7h531.8L304.1,1250.7z"/>
    <path fill="#FFCD40" d="M2073.6,1770.2c0-157.3-37.8-305.8-104.8-436.9h-856.1l339.8,588.6h609.1C2069.5,1872.5,2073.6,1821.8,2073.6,1770.2z"/>
    <path fill="#F1F1F1" d="M1112.7,1333.3c-241.3,0-436.8,195.6-436.8,436.9c0,53.4,9.6,104.5,27.1,151.7h94.8c-22.2-45.9-34.6-97.4-34.6-151.7c0-193.1,156.5-349.5,349.5-349.5s349.5,156.4,349.5,349.5c0,54.4-12.4,105.8-34.6,151.7h94.8c17.5-47.3,27.1-98.4,27.1-151.7C1549.5,1528.9,1353.9,1333.3,1112.7,1333.3z"/>
    <path fill="#4285F4" d="M1112.7,1420.7c-193,0-349.5,156.4-349.5,349.5c0,54.4,12.4,105.8,34.6,151.7h629.8c22.2-45.9,34.6-97.4,34.6-151.7C1462.1,1577.1,1305.7,1420.7,1112.7,1420.7z"/>
    <path opacity="0.05" fill="#212121" enable-background="new" d="M0,0v961h2225.3V0H0z M1365.5,404.7H859.8c-55.9,0-101.2-45.3-101.2-101.2c0-55.8,45.3-101.1,101.2-101.1h505.8c55.9,0,101.2,45.3,101.2,101.1C1466.7,359.4,1421.4,404.7,1365.5,404.7z"/>
    <rect y="948.3" opacity="0.02" fill="#212121" enable-background="new" width="2225.3" height="12.7"/>
    <rect y="961" opacity="0.05" fill="#FFFFFF" enable-background="new" width="2225.3" height="12.7"/>
    <path opacity="0.02" fill="#212121" enable-background="new" d="M0,0v12.7h2225.3V0H0z M1365.5,404.7H859.8c-53.7,0-97.6-42-100.8-94.8c-0.3,2.1-0.4,4.2-0.4,6.3c0,55.9,45.3,101.1,101.2,101.1h505.7c55.9,0,101.2-45.2,101.2-101.1c0-2.1-0.1-4.2-0.4-6.3C1463.1,362.6,1419.3,404.7,1365.5,404.7z"/>
    <path opacity="0.1" fill="#231F20" enable-background="new" d="M2073.6,1909.2H151.7C68.3,1909.2,0,1841,0,1757.5v12.7c0,83.4,68.3,151.7,151.7,151.7h1921.9c83.4,0,151.7-68.3,151.7-151.7v-12.7C2225.3,1841,2157,1909.2,2073.6,1909.2z M859.8,202.3h505.7c53.7,0,97.6,42,100.8,94.8c0.1-2.1,0.4-4.2,0.4-6.3c0-55.9-45.3-101.2-101.2-101.2H859.8c-55.9,0-101.2,45.3-101.2,101.2c0,2.1,0.1,4.2,0.4,6.3C762.2,244.3,806,202.3,859.8,202.3z"/>
  </g>
</svg>`;

const indeedIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="40" viewBox="0 -7 32 40">
  <path d="M19.76 13.745c-3.197 1.64-6.776-1.417-5.661-4.833 1.12-3.417 5.813-3.767 7.423-0.552 1.005 1.973 0.219 4.389-1.761 5.389zM15.469 0.76c3.291-1.197 7.057-1.135 9.875 1.313 0.593 0.489 1.063 1.115 1.364 1.828 0.281 0.916-1-0.095-1.172-0.224-0.891-0.609-1.853-1.099-2.869-1.464-5.552-1.697-10.803 1.396-14.047 6.208-1.323 2.12-2.333 4.423-3 6.833-0.063 0.297-0.156 0.584-0.281 0.86-0.145 0.271-0.068-0.735-0.068-0.765 0.12-1.005 0.313-2.005 0.573-2.985 1.505-5.244 4.839-9.609 9.625-11.609zM15.516 28.755v-11.687c0.333 0.036 0.645 0.052 0.979 0.052 1.516 0.005 3-0.412 4.297-1.193v12.823c0 1.1-0.204 1.907-0.699 2.439-0.495 0.541-1.197 0.839-1.932 0.812-0.724 0.027-1.421-0.271-1.907-0.812-0.489-0.537-0.744-1.349-0.744-2.433z"/>
</svg>`;

const nlpIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
  <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
    <path d="M2515 5114 c-157 -18 -228 -31 -340 -59 -336 -86 -628 -249 -868 -484 -167 -163 -279 -318 -372 -514 -152 -324 -196 -646 -134 -1002 23 -134 24 -234 3 -306 -9 -30 -85 -190 -170 -356 -168 -329 -175 -352 -134 -418 24 -38 52 -53 173 -90 144 -45 131 -11 137 -377 5 -308 5 -314 30 -363 31 -64 92 -124 155 -156 49 -24 54 -24 395 -29 338 -5 346 -5 382 -28 21 -13 45 -40 57 -66 20 -40 21 -59 21 -392 0 -344 1 -350 23 -389 13 -22 40 -49 62 -62 l40 -23 917 0 c625 0 926 3 945 11 35 13 70 45 91 84 13 24 16 56 14 165 -4 184 15 630 33 809 55 534 192 1004 394 1356 191 331 258 548 268 860 9 283 -30 481 -145 736 -127 281 -388 573 -687 769 -247 161 -579 279 -890 315 -116 13 -319 18 -400 9z m-397 -1278 c15 -8 38 -26 52 -41 l25 -27 3 -549 c3 -603 4 -585 -58 -629 -61 -43 -147 -35 -193 20 -13 15 -98 145 -188 289 l-164 261 -5 -262 c-5 -253 -6 -264 -28 -295 -28 -40 -98 -68 -147 -59 -47 9 -102 59 -115 104 -6 23 -10 231 -10 547 0 481 2 512 20 552 27 61 70 85 143 80 51 -3 62 -8 91 -38 18 -19 105 -148 192 -288 l159 -255 5 250 c4 224 7 253 24 279 19 30 47 54 76 67 26 12 90 9 118 -6z m602 -8 c71 -49 70 -41 70 -540 l0 -448 75 0 c42 0 91 -6 109 -14 75 -31 109 -140 64 -205 -49 -72 -61 -76 -249 -79 -155 -3 -174 -2 -213 17 -27 14 -50 35 -64 60 -22 38 -22 42 -22 577 0 493 2 542 18 574 38 77 142 105 212 58z m1143 2 c126 -39 232 -144 278 -275 17 -49 20 -77 17 -155 -3 -81 -8 -105 -35 -163 -78 -166 -229 -257 -425 -257 l-78 0 0 -169 c0 -158 -1 -170 -23 -202 -47 -70 -153 -87 -216 -33 -62 52 -62 54 -59 639 l3 530 23 36 c38 62 63 69 268 69 154 0 192 -3 247 -20z"/>
    <path d="M3620 3416 l0 -136 65 0 c118 0 175 46 175 143 0 40 -5 53 -34 85 -34 36 -36 37 -120 41 l-86 3 0 -136z"/>
  </g>
</svg>`;

const redisIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
  <path d="M511.8,305.9c-0.2,4.9-6.7,10.3-19.9,17.2c-27.3,14.2-168.8,72.5-199,88.2c-30.2,15.7-46.8,15.6-70.7,4.2c-23.8-11.3-174.4-72.3-201.6-85.2C7.2,323.8,0.3,318.4,0,313.2V365c0,5.2,7.2,10.6,20.7,17.1c27.2,13,177.8,73.8,201.6,85.2c23.8,11.4,40.5,11.6,70.7-4.2c30.2-15.7,171.7-73.9,199-88.2c13.9-7.2,20.1-12.8,20.1-17.9c0-4.8,0-51,0-51L511.8,305.9L511.8,305.9z M511.8,221.6c-0.3,4.8-6.7,10.3-19.9,17.2c-27.3,14.2-168.8,72.4-199,88.1c-30.2,15.7-46.8,15.6-70.7,4.3c-23.8-11.4-174.4-72.3-201.6-85.2C7.2,239.5,0.3,234,0,228.9v51.7c0,5.2,7.2,10.7,20.7,17.1c27.2,13,177.7,73.8,201.6,85.2c23.8,11.3,40.5,11.5,70.7-4.2c30.2-15.7,171.7-73.9,199-88.2c13.9-7.2,20.1-12.9,20.1-18c0-4.8,0-51,0-51H511.8z M511.8,134c0.3-5.2-6.5-9.7-20.3-14.7c-26.6-9.7-167.3-65.7-194.2-75.7c-26.9-9.8-37.9-9.4-69.6,1.9C196.1,57,46.3,115.7,19.7,126.1c-13.3,5.2-19.8,10.1-19.6,15.2v51.7c0,5.2,7.1,10.6,20.7,17.1c27.1,13,177.7,73.8,201.6,85.2c23.7,11.3,40.5,11.5,70.7-4.2c30.1-15.7,171.7-73.9,199-88.1c13.8-7.2,20-12.9,20-18c0-4.8,0-51.1,0-51.1L511.8,134z M183.3,183L302,164.9l-35.8,52.5L183.3,183z M445.7,135.7L368,166.4l-70.2-27.7l77.7-30.7L445.7,135.7z M239.7,84.9l-11.5-21.2l35.8,14l33.7-11l-9.2,21.8l34.4,12.9l-44.3,4.6l-10,23.9l-16-26.7l-51.2-4.6L239.7,84.9z M151.3,114.8c35.1,0,63.4,11,63.4,24.5c0,13.6-28.4,24.6-63.4,24.6s-63.4-11-63.4-24.6C87.9,125.8,116.3,114.8,151.3,114.8z"/>
</svg>`;

// <path d="M 19.494 11.539 C 15.911 13.378 11.9 9.951 13.149 6.123 C 14.405 2.293 19.665 1.901 21.469 5.504 C 22.596 7.715 21.715 10.423 19.495 11.544 L 19.494 11.539 Z M 14.738 28.363 L 14.738 15.264 C 15.111 15.304 15.461 15.322 15.835 15.322 C 17.534 15.328 19.197 14.86 20.651 13.985 L 20.651 28.357 C 20.651 29.59 20.422 30.494 19.868 31.091 C 19.313 31.697 18.526 32.031 17.702 32.001 C 16.891 32.031 16.11 31.697 15.565 31.091 C 15.017 30.489 14.731 29.579 14.731 28.364 L 14.738 28.363 Z" style=""/>
// <path d="M19.76 13.745c-3.197 1.64-6.776-1.417-5.661-4.833 1.12-3.417 5.813-3.767 7.423-0.552 1.005 1.973 0.219 4.389-1.761 5.389zM15.469 0.76c3.291-1.197 7.057-1.135 9.875 1.313 0.593 0.489 1.063 1.115 1.364 1.828 0.281 0.916-1-0.095-1.172-0.224-0.891-0.609-1.853-1.099-2.869-1.464-5.552-1.697-10.803 1.396-14.047 6.208-1.323 2.12-2.333 4.423-3 6.833-0.063 0.297-0.156 0.584-0.281 0.86-0.145 0.271-0.068-0.735-0.068-0.765 0.12-1.005 0.313-2.005 0.573-2.985 1.505-5.244 4.839-9.609 9.625-11.609zM15.516 28.755v-11.687c0.333 0.036 0.645 0.052 0.979 0.052 1.516 0.005 3-0.412 4.297-1.193v12.823c0 1.1-0.204 1.907-0.699 2.439-0.495 0.541-1.197 0.839-1.932 0.812-0.724 0.027-1.421-0.271-1.907-0.812-0.489-0.537-0.744-1.349-0.744-2.433z"/>

// https://klarsys.github.io/angular-material-icons/
const removeBusiness = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48">
<defs>
  <mask id="cut-off-diagonal">
    <rect x="0" y="0" width="24" height="24" fill="white"></rect>
    <path d="M 22.73 22.73 L 1.27 1.27 L 0 2.54 L 21.46 24 L 22.73 22.73 Z" fill="black" transform="translate(1 -1)">
    </path>
  </mask>
</defs>

<path d="M20 19h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zM10 7H8V5h2v2zm0 4H8V9h2v2zm0 4H8v-2h2v2zm0 4H8v-2h2v2zM6 7H4V5h2v2zm0 4H4V9h2v2zm0 4H4v-2h2v2zm0 4H4v-2h2v2zm6-12V3H2v18h20V7H12z" mask="url(#cut-off-diagonal)"></path>
<path d="M18 11h-2v2h2v-2z" mask="url(#cut-off-diagonal)"></path>
<path d="M18 15h-2v2h2v-2z" mask="url(#cut-off-diagonal)"></path>

<path d="M 22.73 22.73 L 1.27 1.27 L 0 2.54 L 21.46 24 L 22.73 22.73 Z"></path>
</svg>`;

const removeRecentActors = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48">
<defs>
  <mask id="cut-off-diagonal">
    <rect x="0" y="0" width="24" height="24" fill="white"></rect>
    <path d="M 22.73 22.73 L 1.27 1.27 L 0 2.54 L 21.46 24 L 22.73 22.73 Z" fill="black" transform="translate(1 -1)">
    </path>
  </mask>
</defs>

<path d="M17 19h2V5h-2v14zm4-14v14h2V5h-2z" mask="url(#cut-off-diagonal)"></path>
<path d="M12.5 17h-9v-.75c0-1.5 3-2.25 4.5-2.25s4.5.75 4.5 2.25V17zM8 7.75c1.24 0 2.25 1.01 2.25 2.25S9.24 12.25 8 12.25 5.75 11.24 5.75 10 6.76 7.75 8 7.75zM14 5H2c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h12c.55 0 1-.45 1-1V6c0-.55-.45-1-1-1z" mask="url(#cut-off-diagonal)"></path>

<path d="M 22.73 22.73 L 1.27 1.27 L 0 2.54 L 21.46 24 L 22.73 22.73 Z"></path>
</svg>`;