// api
export enum ApiResponseStatus {
  invalidCredentials = 'INVALID_AUTH_CREDENTIALS',
  invalidToken = 'INVALID_AUTH_TOKEN',
  payloadTooLarge = 'PAYLOAD_TOO_LARGE',
  reusedPassword = 'REUSED_PASSWORD',
  invalidPassword = 'INVALID_PASSWORD',
  invalidPasswordReqs = 'INVALID_PASSWORD_REQS',
  invalidVerificationCode = 'INVALID_VERIFICATION_CODE'
}

// intake form
export enum CriteriaKeys {
  JobRoles = 'jobRoles',
  Skills = 'skills',
  Certifications = 'certifications'
}

// jobPosting/allRules
export enum SearchCriteriaRuleIds {
  careerLevel = 1005,
  roles = 1001,
  companies = 1010,
  yearsOfXp = 1020,
  industries = 1030,
  location = 2000,
  workStatus = 2020,
  positionTypes = 2030,
  payBands = 2050,
  skills = 3000,
  certifications = 4000,
  education = 4001,
  preferredMajor = 4002
}

export enum SearchCriteriaRuleKeys {
  careerLevel = 'jobRoleLevels',
  roles = 'jobRoles',
  companies = 'companies',
  yearsOfXp = 'experienceReqs',
  industries = 'industries',
  location = 'jurisdiction',
  workStatus = 'workStatuses',
  positionTypes = 'positionTypes',
  skills = 'skills',
  certifications = 'certifications',
  education = 'degreeTypes',
  preferredMajor = 'preferredMajors'
}

export enum RulerFactoryOption {
  Start = 'START',
  End = 'END',
  Default = 'DEFAULT'
}

export enum JobType {
  DIRECT_HIRE = 1000,
  STAFFING = 2000
}

export enum CandidateRestrictionStatus {
  NON_RESTRICTED = 0,
  RESTRICTED = 1
}

export enum CandidateFollowStatus {
  NON_FOLLOWED = 0,
  FOLLOWED = 1
}

export enum OmniSearchFilters {
  CANDIDATES = 'Candidates',
  JOBS = 'Jobs'
}

export enum CandidateResponse {
  INTEREST = 'interest',
  DECLINE = 'decline'
}

export enum MemberLead {
  NOT_FOUND = -1,
  NOT_LEAD = 0,
  IS_LEAD = 1
}
