import {
  Component,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  OnChanges
} from '@angular/core';
import { Subject } from 'rxjs';

import { PageEvent } from '@angular/material/paginator';
import { RulerFactoryOption } from 'src/app/enum';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PaginatorComponent implements OnDestroy, OnChanges {
  private destroy$ = new Subject<void>();

  @Input() pageIndex = 0;
  @Input() pageSize = 0;
  @Input() length = 0;
  @Input() hideIfSinglePage = true;
  @Output() page = new EventEmitter<PageEvent>();

  Math = Math;
  totalPages = 0;
  pagesList: number[] = [];

  rulerLength = 5;
  pagination?: NumberedPagination;
  constructor() { }

  incrementPage(i: 1 | -1) {
    if (this.pageIndex == null || this.pageSize == null) return;

    const pageIndex = Math.min(
      Math.max(this.pageIndex + i, 0),
      this.length ? Math.ceil(this.length / this.pageSize) - 1 : 1e9
    );

    this.page.emit({
      pageIndex,
      previousPageIndex: this.pageIndex,
      pageSize: this.pageSize,
      length: this.length || 0
    });

    this.pageIndex = pageIndex;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }


  generatePagesList() {
    this.totalPages = parseInt(
      Math.ceil(this.length / parseFloat(this.pageSize + '')) + ''
    );

    this.pagesList = new Array(this.totalPages).fill(null).map((_, i) => i + 1);
  }
  jumpTo($value: any) {
    const pageIndex = Math.min(
      Math.max($value.value, 0),
      this.length ? Math.ceil(this.length / this.pageSize) - 1 : 1e9
    );
    this.page.emit({
      pageIndex,
      previousPageIndex: this.pageIndex,
      pageSize: this.pageSize,
      length: this.length || 0
    });

    this.pageIndex = pageIndex;
  }

  changePageSize($value: any) {
    this.page.emit({
      pageIndex: 0,
      previousPageIndex: 1,
      pageSize: $value.value,
      length: this.length || 0
    });
    this.pageSize = $value.value;
    this.pageIndex = 0;
  }
  get generatePaginationRuler(): NumberedPagination {
    this.rulerLength = this.totalPages >= 5 ? 5 : this.totalPages;
    const { pageIndex, totalPages, rulerLength } = this;
    const pages = ruler(pageIndex, totalPages, rulerLength);
    return {
      index: pageIndex,
      maxPages: totalPages,
      pages
    } as NumberedPagination;
  }

  navigateToPage(pageNumber: number): void {
    if (allowNavigation(pageNumber, this.pageIndex, this.totalPages)) {
      this.pageIndex = pageNumber;

      this.page.emit({
        pageIndex: pageNumber,
        previousPageIndex: this.pageIndex,
        pageSize: this.pageSize,
        length: this.length || 0
      });
    }
  }

  trackByFn(index: number): number {
    return index;
  }
  ngOnChanges() {

    this.generatePagesList();
    this.pagination = this.generatePaginationRuler;
  }
}

const ruler = (
  currentIndex: number,
  maxPages: number,
  rulerLength: number
): number[] => {
  const array = new Array(rulerLength).fill(null);
  const min = Math.floor(rulerLength / 2);

  return array.map((_, index) =>
    rulerFactory(currentIndex, index, min, maxPages, rulerLength)
  );
};

const rulerOption = (
  currentIndex: number,
  min: number,
  maxPages: number
): RulerFactoryOption => {
  return currentIndex <= min
    ? RulerFactoryOption.Start
    : currentIndex >= maxPages - min
      ? RulerFactoryOption.End
      : RulerFactoryOption.Default;
};

const rulerFactory = (
  currentIndex: number,
  index: number,
  min: number,
  maxPages: number,
  rulerLength: number
): number => {
  const factory = {
    [RulerFactoryOption.Start]: () => index + 1,
    [RulerFactoryOption.End]: () => maxPages - rulerLength + index + 1,
    [RulerFactoryOption.Default]: () => currentIndex + index - min
  };

  return factory[rulerOption(currentIndex, min, maxPages)]();
};

const allowNavigation = (
  pageNumber: number,
  index: number,
  maxPages: number
): boolean => {
  return pageNumber !== index && pageNumber >= 0 && pageNumber <= maxPages;
};
export interface NumberedPagination {
  index: number;
  maxPages: number;
  pages: number[];
}
