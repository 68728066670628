import { Component, OnInit, OnDestroy, Input, Pipe, PipeTransform } from '@angular/core';
import { Subject, BehaviorSubject, of, combineLatest, merge } from 'rxjs';
import { takeUntil, filter, map, switchMap, startWith, take } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { format, formatDistanceToNowStrict, isBefore } from 'date-fns';
import { isEqual } from 'lodash-es';

import { successData, mapSuccessDataTable, truthy, switchMapSuccess } from 'src/app/utils/rxjs-operators';

import { TasksService, Task, Filter } from 'src/app/services/api/tasks.service';

import { ApiResult, DataTable } from 'src/app/services/api.service';
import { ContextService } from 'src/app/services/context.service';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-pr-tasks-list',
  templateUrl: './pr-tasks-list.component.html',
  styleUrls: ['./pr-tasks-list.component.scss']
})
export class TasksListComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  @Input() header = true;
  @Input() filter = true;
  @Input() sidebar = true;
  @Input() profileRef?: string;

  private _listResult$ = new BehaviorSubject<ApiResult<DataTable<Task>> | undefined>(undefined);

  @Input()
  public get listResult() {
    return this._listResult$.value;
  }

  public set listResult(value) {
    this._listResult$.next(value);
  }

  taskFilters$ = this.tasks.filters().pipe(
    takeUntil(this.destroy$)
  );

  taskFilter$ = new BehaviorSubject<Filter | undefined>(undefined);

  paginationOffset$ = new BehaviorSubject(0);
  tasks$ = merge(
    this._listResult$.pipe(
      truthy()
    ),
    combineLatest([
      this.taskFilter$.pipe(
        truthy()
      ),
      this.paginationOffset$
    ]).pipe(
      switchMap(([_filter, offset]) => this.tasks.get(_filter.filterId, this.profileRef, { offset }))
    )
  ).pipe(
    mapSuccessDataTable(item => ({ ...item, taskDueDate: format(+item.taskDueDateEpoch * 1000, 'PP p') })),
    takeUntil(this.destroy$)
  );

  saveTask$ = new Subject<{ item: Task, updates: Partial<Task> }>();
  saveTaskResult$ = this.saveTask$.pipe(
    map(({ item, updates }) => ({
      origItem: { ...item },
      newItem: { ...item, ...updates }
    })),
    filter(({ origItem, newItem }) => !isEqual(origItem, newItem)),
    switchMap(({ origItem, newItem }) =>
      this.tasks.save(newItem).pipe(
        switchMap(result =>
          of(result).pipe(
            switchMapSuccess(() =>
              this.snackBar.open('Saved', 'Undo', { duration: 5 * 1000 }).onAction().pipe(
                switchMap(() => this.tasks.save(origItem)),
                startWith(result)
              )
            )
          )
        )
      )
    ),
    takeUntil(this.destroy$)
  );

  ngOnInit() {
    this._listResult$.pipe(
      take(1),
      switchMap(data =>
        !data ? this.taskFilters$.pipe(
          successData(),
          map(filters => filters[0])
        ) : of(null)
      ),
      takeUntil(this.destroy$)
    ).subscribe(filter => {
      if (filter) this.taskFilter$.next(filter);
    });
  }

  constructor(
    private tasks: TasksService,

    private context: ContextService,
    private dialog: DialogService,
    private snackBar: MatSnackBar
  ) {
    this.saveTaskResult$.pipe(
      takeUntil(this.destroy$)
    ).subscribe();
  }

  changePage(event: PageEvent) {
    this.paginationOffset$.next(event.pageIndex * event.pageSize);
    // this.paginationNumResults$.next(event.pageSize);
  }

  viewTask(task: Task) {
    if (task.isOwner === 1) {
      this.taskDialog('Edit Task', task);
    } else {
      this.taskDialog(task.title, task);
    }
  }

  taskDialog(title: string, task?: Task) {
    if (!task) {
      task = { ...this.context.latest, isOwner: 1 } as Task;
    }

    this.dialog.task({ dialog: { title }, item: { ...task } }).pipe(
      truthy(),
      takeUntil(this.destroy$)
    ).subscribe(newTask => {
      const item = { ...newTask, isActive: 0 as const };
      const updates = { isActive: 1 as const };

      this.saveTask(item, updates);
    });
  }

  saveTask(task: Task, updates: Partial<Task>) {
    this.saveTask$.next({ item: task, updates });
  }

  closeSidebar() {
    this.tasks.closeSidebar();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

@Pipe({ name: 'fromNow' })
export class FromNowPipe implements PipeTransform {
  transform(items: Task[]): any[] {
    return (items || []).map(x => ({
      obj: x,
      ...x,
      taskDueDate: format(+x.taskDueDateEpoch * 1000, 'PP p')
    })).map(x => ({
      ...x,
      fromNow: isBefore(+x.taskDueDateEpoch * 1000, Date.now()) ? 'overdue' : formatDistanceToNowStrict(+x.taskDueDateEpoch * 1000, { addSuffix: true })
    }));
  }
}