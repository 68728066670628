import { Injectable, OnDestroy } from '@angular/core';
import { ApiService } from '../api.service';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { mapSuccessData, tapSuccess } from 'src/app/utils/rxjs-operators';
import { City, Company, JobRole, Region } from './xref.service';
import { Skill } from './skills.service';
import { Priority } from './job-postings.service';

@Injectable({
  providedIn: 'root'
})
export class JobService implements OnDestroy {
  private destroy$ = new Subject<void>();
  public jobMemeber$ = new BehaviorSubject<boolean>(false);
  public jobHeader$ = new BehaviorSubject<boolean | undefined>(undefined);
  public jobInfo$ = new BehaviorSubject<boolean | undefined>(undefined);

  getJobMembers = (jobId?: number) =>
    this.api
      .get<jobMember[]>({
        path: 'job/members',
        params: { jobId }
      })
      .pipe(takeUntil(this.destroy$));

  addJobMember = (jobMemberBody: JobMemberBody) =>
    this.api
      .post<void>({
        path: 'job/members',
        body: jobMemberBody
      })
      .pipe(
        tapSuccess(() => this.jobMemeber$.next(true)),
        takeUntil(this.destroy$)
      );

  getJobInfo = (jobId?: number) =>
    this.api.get<JobInfo>({
      path: 'job/info',
      params: { jobId }
    });

  getJobDesc = (jobId?: number) =>
    this.api
      .get<JobDesc[]>({
        path: 'job/desc',
        params: { jobId }
      })
      .pipe(
        mapSuccessData(data => data[0]),
        takeUntil(this.destroy$)
      );

  updateJobStatus = (jobId: number, jobPostingStatusId: number, jobCloseReasonId?: number) =>
    this.api
      .post<void>({
        path: 'job/status',
        body: { jobId, jobPostingStatusId, jobCloseReasonId }
      })
      .pipe(
        // tapSuccess(() => this.jobHeader$.next(true)),
        takeUntil(this.destroy$)
      );

  public JobDesc$ = new BehaviorSubject<boolean>(false);

  saveDesc = (desc: JobDescBody, jobId: number) =>
    this.api
      .post<any>({
        path: 'job/desc',
        params: { jobId },
        body: desc
      })
      .pipe(takeUntil(this.destroy$));

  saveJobInfo = (info: JobInfoBody, jobId: number) =>
    this.api
      .post<any>({
        path: 'job/info',
        params: { jobId },
        body: info
      })
      .pipe(
        tapSuccess(() => this.jobInfo$.next(true)),
        takeUntil(this.destroy$)
      );

  changeOutreachStatus = (jobId: number, status: number) =>
    this.api
      .post<any>({
        path: 'job/outreach-status',
        body: {
          jobId,
          status
        }
      })
      .pipe(
        tapSuccess(() => this.jobHeader$.next(true)),
        takeUntil(this.destroy$)
      );

  getJobHeader = (jobId?: number) =>
    this.api
      .get<JobHeader[]>({
        path: 'job/header',
        params: { jobId }
      })
      .pipe(
        mapSuccessData(data => data[0]),
        takeUntil(this.destroy$)
      );

  addCandidateToJob = (jobId: number, profileRef: string) =>
    this.api
      .post<any>({
        path: 'job/add-profile',
        body: { jobId, profileRef }
      })
      .pipe(takeUntil(this.destroy$));

  constructor(private api: ApiService) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

export interface jobMember {
  userId: number;
  userName: string;
  projectRoleId: number;
  projectRoleDesc: string;
  isLead: number;
}
export interface jobMemberMapper {
  jobRoleId: string;
  userId: number;
  userName: string;
  jobRoleDesc: string;
}

export interface JobMemberBody {
  jobId: number;
  members: Member[];
}

export interface Member {
  userId: number;
  projectRoleId: number;
  isActive: number;
  isLead: number;
}

export interface JobInfo {
  info: Info[];
  roleLevels: RoleLevel[];
  workStatuses: WorkStatuse[];
  positionTypes: PositionType[];
  workLocations: WorkLocation[];
}

export interface Info {
  jobPostingId: number;
  jobId: number;
  jobTitle: string;
  jobPostingStatusId: number;
  jobPostingStatusDesc: string;
  companyId: number;
  companyName: string;
  locationGeoId: number;
  locationGeoDisplay: string;
  minPayRate: number;
  maxPayRate: number;
  payRateTypeId: number;
  numPlacements: number;
  emailTemplateStyleId: number | null;
}

export interface RoleLevel {
  jobPostingId: number;
  levelId: number;
  levelDesc: string;
}

export interface WorkStatuse {
  jobPostingId: number;
  workStatusId: number;
  workStatusDesc: string;
}

export interface PositionType {
  jobPostingId: number;
  positionTypeId: number;
  positionType: string;
}

export interface WorkLocation {
  jobPostingId: number;
  workLocationId: number;
  workLocationDesc: string;
}

export interface JobDesc {
  jobPostingId: number;
  jobId: number;
  additionalInfoText: string;
  jobDescRich: string;
}

export interface JobDescBody {
  additionalInfoText: string;
  jobDesc: string;
  jobDescRich: string;
}

export interface JobInfoBody {
  jobTitle: string;
  companyId: number;
  locationGeoId: number;
  minPayRate: number;
  maxPayRate: number;
  payRateTypeId: number;
  numPlacements: number;
  roleLevels: number[];
  workStatuses: number[];
  employmentTypes: number[];
  workLocations: number[];
  emailTemplateStyleId: number | null;
  companyName: string;
}

export interface JobHeader {
  jobPostingId: number;
  jobId: number;
  jobTitle: string;
  isStpCommEnabled: number;
  companyName: string;
  companyId: number;
  templateTypeId: number;
  templateId: number;
  templateName: string;
}

export interface PriorityFilter {
  priorityId: number;
  priorityDesc: string;
}

export class advanceFilter {
  jobRolesIds: JobRole[] = [];
  skillsIds: Skill[] = [];
  certificatesIds: Skill[] = [];
  degreeTypeId = 0;
  expBandIds: number[] = [];
  statesIds: Region[] = [];
  citiesIds: City[] = [];
  distanceId = 0;
  sourceIds: number[] = [];
  jobRoleLevelId = 0;
  industriesIds: Skill[] = [];
  companiesIds: Company[] = [];
  excludeCompaniesIds: Company[] = [];
  prioritiesIds: Priority[] = [];
}
