import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, Subject, zip } from 'rxjs';
import { takeUntil, map, switchMap, share, tap, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { truthy, successData, mapSuccessDataTable } from 'src/app/utils/rxjs-operators';

import { CandidatesService, Candidate } from 'src/app/services/api/candidates.service';
import { JobPostingHeader, JobPostingsService } from 'src/app/services/api/job-postings.service';
import { NotesService } from 'src/app/services/api/notes.service';
import { TasksService } from 'src/app/services/api/tasks.service';

import { ApiResult, DataTable, Item } from 'src/app/services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { CandidateModalComponent } from '../candidate-popout-module/candidate-modal/candidate-modal.component';
import { ContextService } from 'src/app/services/context.service';
import { CandidateFollowStatus, CandidateRestrictionStatus, OmniSearchFilters } from 'src/app/enum';

@Component({
  selector: 'app-omni-search',
  templateUrl: './pr-omni-search.component.html',
  styleUrls: ['./pr-omni-search.component.scss']
})
export class OmniSearchComponent implements OnDestroy {
  private destroy$ = new Subject<void>();

  searchText$ = new Subject<string>();
  isLoading = false;
  OmniSearchFilters = OmniSearchFilters;
  CandidateRestrictionStatus = CandidateRestrictionStatus;
  CandidateFollowStatus = CandidateFollowStatus;

  filters: (Filter<JobPostingHeader> | Filter<Candidate>)[] = [
    {
      title: 'Jobs',
      search: (searchValue: string) =>
        this.jobPostings
          .getJobs({ searchValue })
          .pipe(mapSuccessDataTable(item => ({ value: item, text: (item.companyName ? item.companyName + ' - ' : '') + `${item.jobTitle} (Job ID: ${item.jobId})` }))),
      click: (item: Item<JobPostingHeader>) => {
        void this.router.navigate(['job', item.value.jobId, 1]);
      }
    },
    {
      title: 'Candidates',
      search: (searchValue: string) => this.candidates.get({ searchValue, jobId: this.context.context[0]?.jobId }).pipe(mapSuccessDataTable(item => ({ value: item, text: item.displayName }))),
      click: (item: Item<Candidate>) => {
        this.dialogMat.open(CandidateModalComponent, {
          minWidth: '1300px',
          panelClass: 'custom-dialog-container',
          data: {
            jobId: this.context.context[0]?.jobId,
            profileRef: item.value.profileRef,
            candidate: item.value,
            total: 0,
            tableData: [],
            index: 0
          }
        });
        // this.candidates.showSidebar(item.value);
      }
    }
    // {
    //   title: 'Notes',
    //   search: (searchValue: string) => this.omniSearch.get('1003', searchValue),
    //   click: (item: Item) => {
    //     this.notes.showSidebar(item.value);
    //   }
    // },
    // {
    //   title: 'Tasks',
    //   search: (searchValue: string) => this.omniSearch.get('1004', searchValue),
    //   click: (item: Item) => {
    //     this.tasks.showSidebar(item.value);
    //   }
    // }
  ];

  data$ = this.searchText$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    truthy(),
    switchMap(searchValue => {
      if (searchValue.length > 0) {
        return of(null).pipe(
          tap(() => (this.isLoading = true)),
          switchMap(() => zip(...Object.values(this.filters).map(_filter => (_filter as Filter<any>).search(searchValue).pipe(successData())))),
          tap(() => (this.isLoading = false)),
          map(x => x.map((y, i) => ({ filter: this.filters[i], items: y.dataTable })).filter(y => y.items.length > 0))
        );
      } else {
        return of([]);
      }
    }),
    share(),
    takeUntil(this.destroy$)
  );

  constructor(
    private candidates: CandidatesService,
    private jobPostings: JobPostingsService,
    private notes: NotesService,
    private tasks: TasksService,
    private router: Router,
    private dialogMat: MatDialog,
    public context: ContextService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

interface Filter<T> {
  title: string;
  search: (text: string) => Observable<ApiResult<DataTable<Item<T>>>>;
  click: (item: Item<T>) => void;
}
